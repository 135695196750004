import './add-iban.scss';
import { Divider } from 'antd';
import { Alert, Button, Card } from 'components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReturnArrow } from 'assets/icons/bank-accounts/ReturnArrow.svg';
import { ReactComponent as BankCard } from 'assets/icons/bank-accounts/Card.svg';
import { createBankAccountByIBan } from '../../../../core/repositories';

export const AddIBan = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [iBan, setIBan] = useState<string>('');

  const handleIBanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIBan(e.target.value);
  };

  const handleAddIBan = async () => {
    iBan.length === 24 && (await createBankAccountByIBan(setLoading, iBan));
  };

  return (
    <Card
      className="xpay-add-iban"
      title="افزودن شماره شبا"
      button={
        <Button
          onClick={() => navigate('/dashboard/bank-accounts')}
          type="primary"
          text="بازگشت"
          icon={<ReturnArrow />}
        />
      }
      divider
    >
      <div className="add-section">
        <h4 className="field-label">شماره شبا</h4>
        <div className="field">
          <BankCard />
          <div>
            <input
              id="iBan"
              name="iBan"
              value={iBan}
              onChange={(e) => handleIBanChange(e)}
              maxLength={24}
            />
            <span id="ir" className="en">
              IR
            </span>
          </div>
        </div>
        <Button
          text="ثبت شماره شبا"
          type="primary"
          onClick={handleAddIBan}
          loading={loading}
          disabled={iBan.length !== 24}
        />
      </div>
      <Divider className="vertical-divider" type="vertical" />
      <div className="hint-section">
        <Alert
          type="warning"
          title="اخطار!"
          message="شماره شبا باید به نام خودتان باشد."
        />
        <p>
          بنا به مسائل امنیتی واریز و برداشت تنها به کارت های ثبت شده امکان پذیر
          است. بعدا میتوانید با کلیک روی دکمه ی ویرایش اطلاعات، کارت های مجاز را
          ویرایش کنید.
          <br />
          <br />
          در صورت ورود اطلاعات اشتباه مثل شماره شبا، در صورت برداشت به شماره ی
          اشتباه صرافی ایکس پی هیچگونه مسئولیتی در این خصوص نمیپذیرد اشتباه
          صرافی ایکس پی هیچگونه مسئولیتی در این خصوص نمیپذیرد در صورت ورود
          اطلاعات اشتباه مثل شماره شبا، در صورت برداشت به شماره ی اشتباه صرافی
          ایکس و تمامی عواقب احتمالی بر عهده ی کاربر خواهد بود. در صورت برداشت
          به شماره ی اشتباه صرافی ایکس پی هیچگونه مسئولیتی در این خصوص نمیپذیرد.
          در صورت برداشت به شماره ی اشتباه صرافی ایکس پی هیچگونه مسئولیتی در این
          خصوص نمیپذیرد.
        </p>
      </div>
    </Card>
  );
};
