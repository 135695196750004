import * as React from 'react';
import './notification.scss';

import { ReactComponent as Dot } from 'assets/icons/blue-dot.svg';
import { ReactComponent as Polygan } from 'assets/icons/polygan-3.svg';
import { Button } from 'components/button/button';
import useOnBlur from 'utils/scripts/useOnBlur';
import { RootState } from 'core/redux/store';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import {
  getMineNotificationsDispatch,
  readNotification,
} from 'core/repositories/notification/notification';
import { setNotifications } from 'core/redux/actions';
import { Notification } from 'core/domains/notifications/notification';
import moment from 'jalali-moment';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../spinner/spinner';
import { Dispatch, SetStateAction } from 'react';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const Notifications: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!window.location.href.includes('verify')) {
      props.state.notifications.length === 0 &&
        getMineNotificationsDispatch(setLoading, setNotifications, dispatch, {
          page: 1,
          pageSize: 5,
        });
    }
  }, []);

  const notificationEl = React.useRef<HTMLDivElement>(null);

  useOnBlur(notificationEl, () => props.isOpen && props.setIsOpen(false));

  return (
    <div className="xpay-notification" ref={notificationEl}>
      <div className="icon">
        {props.state.notifications.filter((n: Notification) => !n.isRead)
          .length > 0 && (
          <span
            className="badge"
            onClick={() =>
              props.isOpen ? props.setIsOpen(false) : props.setIsOpen(true)
            }
          >
            {props.state.notifications.length > 0 &&
              props.state.notifications.filter((n: Notification) => !n.isRead)
                .length}
          </span>
        )}
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() =>
            props.isOpen ? props.setIsOpen(false) : props.setIsOpen(true)
          }
        >
          <path
            d="M14.2095 20.8C14.2095 21.1183 14.0839 21.4235 13.8602 21.6485C13.6366 21.8736 13.3332 22 13.017 22H6.65658C6.34029 22 6.03695 21.8736 5.8133 21.6485C5.58965 21.4235 5.46401 21.1183 5.46401 20.8C5.46401 20.4817 5.58965 20.1765 5.8133 19.9515C6.03695 19.7264 6.34029 19.6 6.65658 19.6H13.017C13.3332 19.6 13.6366 19.7264 13.8602 19.9515C14.0839 20.1765 14.2095 20.4817 14.2095 20.8ZM19.4091 16.9992C19.2368 17.3048 18.9866 17.5586 18.6844 17.7345C18.3821 17.9105 18.0387 18.0021 17.6895 18H1.98398C1.63479 18.0021 1.29136 17.9104 0.989079 17.7345C0.686801 17.5586 0.436613 17.3047 0.264298 16.9991C0.0902931 16.6936 -0.000848712 16.3474 5.95595e-06 15.9952C0.000860623 15.643 0.0936817 15.2973 0.269168 14.9926C0.834645 14.0136 1.50894 12.1446 1.50894 8.8V8.4C1.50631 6.17492 2.38218 4.03991 3.9439 2.46462C5.50562 0.889329 7.62526 0.00278308 9.83657 0L9.90017 0.000200081C14.4576 0.0343001 18.1654 3.843 18.1654 8.4904V8.8C18.1654 12.1448 18.8392 14.014 19.4045 14.993C19.5799 15.2977 19.6727 15.6434 19.6735 15.9955C19.6743 16.3476 19.5831 16.6937 19.4091 16.9992ZM17.0287 15.6C16.0062 13.445 15.7802 10.7553 15.7802 8.8V8.4904C15.7802 5.1566 13.1345 2.4244 9.88248 2.4002C9.86734 2.40008 9.8522 2.40002 9.83706 2.4C9.05532 2.40131 8.28148 2.55755 7.55974 2.85979C6.83799 3.16202 6.18248 3.60435 5.63062 4.1615C5.07877 4.71865 4.64138 5.37972 4.34342 6.10696C4.04546 6.83421 3.89278 7.61338 3.89409 8.4V8.8C3.89409 10.7553 3.66789 13.4451 2.64487 15.6H17.0287Z"
            fill="#4E4E4E"
          />
        </svg>
      </div>

      <div className={`notification-box ${props.isOpen ? '' : 'd-none'}`}>
        <Spinner loading={loading}>
          <Polygan className="polygan" />
          {props.state.notifications.slice(0, 4).map(
            (notification: Notification, index) =>
              !notification.isRead && (
                <div
                  key={index}
                  className={`notification-item `}
                  onClick={() =>
                    !notification.isRead &&
                    readNotification(
                      setNotifications,
                      setLoading,
                      notification.id,
                      dispatch,
                    )
                  }
                >
                  {index !== 0 && <span className="divider"></span>}
                  <div className="head">
                    <div className="title">
                      <Dot />
                      <span>{notification.title}</span>
                    </div>
                    <p className="date">
                      {moment
                        .utc(notification.createdOn)
                        .locale('fa')
                        .local()
                        .format('jYYYY/MM/DD')}{' '}
                      -{' '}
                      {moment
                        .utc(notification.createdOn)
                        .local()
                        .format('HH:mm')}
                    </p>
                  </div>
                  <p>{notification.message}</p>
                </div>
              ),
          )}
          <div className="footer">
            <span className="divider"></span>
            <Button
              type="primary"
              text="مشاهده همه"
              onClick={() => {
                props.setIsOpen(false);
                navigate('/dashboard/notifications');
              }}
            />
          </div>
        </Spinner>
      </div>
    </div>
  );
};

export default connector(Notifications);
