import './sell-perfect-money-modal.scss';
import { Button, Card, Modal } from '../../../../../components';
import close from '../../../../../assets/icons/close.png';
import { Divider } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as WalletIcon } from 'assets/icons/create-order/wallet.svg';
import { ReactComponent as GatewayIcon } from 'assets/icons/create-order/gateway.svg';
import { BankAccount, BankAccountResponse } from '../../../../../core/domains';
import { getMineBankAccount } from '../../../../../core/repositories';
import * as React from 'react';
import { Side } from '../create-order';
import { useNavigate } from 'react-router-dom';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';
import { Spinner } from '../../../../../components/spinner/spinner';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  sourceAmount: string;
  destinationAmount: string;
  side: Side;
  voucher: string;
  voucherActivation: string;
}

enum Method {
  Wallet,
  Bank,
}

export const SellPerfectMoneyModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  sourceAmount,
  destinationAmount,
  side,
  voucher,
  voucherActivation,
}) => {
  const [method, setMethod] = useState<Method>(Method.Wallet);
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const createPerfectMoneyOrder = () => {
    if (method === Method.Wallet) {
      setLoading(true);
      postViaAuth(API.transactions.perfectMoney.sell, {
        amount: sourceAmount,
        destination: 0,
        bankAccountId: selectedBankAccount?.id,
        voucherCode: voucher,
        voucherActivationCode: voucherActivation,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('تراکنش با موفقیت ثبت شد.');
        } else {
          toast.error(response.message);
        }
        setIsOpen(false);
      });
    } else if (method === Method.Bank) {
      setLoading(true);
      postViaAuth(API.transactions.perfectMoney.sell, {
        amount: destinationAmount,
        destination: 1,
        bankAccountId: selectedBankAccount?.id,
        voucherCode: voucher,
        voucherActivationCode: voucherActivation,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('تراکنش با موفقیت ثبت شد.');
        } else {
          toast.error(response.message);
        }
        setIsOpen(false);
      });
    }
  };

  useEffect(() => {
    !bankAccounts && isOpen && getMineBankAccount(setLoading, setBankAccounts);
  }, [isOpen]);

  useEffect(() => {
    if (bankAccounts && bankAccounts.length > 0)
      setSelectedBankAccount({
        id: bankAccounts[0].id,
        cardNumber: bankAccounts[0].cardNumber,
        bankName: bankAccounts[0].bank.name,
        iBan: bankAccounts[0].iBan,
      });
  }, [bankAccounts]);

  return (
    <Modal
      className={'sell-perfect-money-modal'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card
        title={'انتخاب روش برداشت'}
        button={
          <img
            style={{ cursor: 'pointer' }}
            src={close}
            alt={'Close'}
            onClick={() => setIsOpen(false)}
          />
        }
        divider
      >
        <div className={'payment-methods'}>
          <div
            className={`payment-method ${method === Method.Wallet ? 'active' : ''}`}
            onClick={() => setMethod(Method.Wallet)}
          >
            <div className={'col'}>
              <WalletIcon />
              <span>واریز به کیف پول</span>
            </div>
          </div>
          <div
            className={`payment-method ${method === Method.Bank ? 'active' : ''}`}
            onClick={() => setMethod(Method.Bank)}
          >
            <div className={'col'}>
              <GatewayIcon />
              <span>واریز به حساب</span>
            </div>
          </div>
        </div>
        <Divider className={'card-divider'} />
        {method === Method.Bank && (
          <>
            <div className={'choose-card'}>
              <p>کارت بانکی خود را برای پرداخت از درگاه انتخاب کنید:</p>
              <Button
                type={'card'}
                text={'افزودن کارت'}
                onClick={() => navigate('/dashboard/bank-accounts/add-card')}
              />
            </div>
            <ul className="cards">
              <Spinner loading={loading}>
                {bankAccounts?.map((item, index) => (
                  <>
                    <li
                      onClick={() =>
                        setSelectedBankAccount({
                          id: item.id,
                          cardNumber: item.cardNumber,
                          bankName: item.bank.name,
                          iBan: item.iBan,
                        })
                      }
                    >
                      <div>
                        <img
                          src={`/banks/${item.bank.code.toLowerCase()}.svg`}
                        />
                        <span>{item.bank.name}</span>
                      </div>
                      <span dir={'ltr'}>{item.cardNumber}</span>
                    </li>
                    {index !== bankAccounts.length - 1 && <Divider />}
                  </>
                ))}
              </Spinner>
            </ul>
            <Button
              type={'primary'}
              text={'پرداخت'}
              onClick={createPerfectMoneyOrder}
              loading={loading}
            />
          </>
        )}
        {method === Method.Wallet && (
          <>
            <Button
              type={'primary'}
              text={'پرداخت'}
              onClick={createPerfectMoneyOrder}
              loading={loading}
            />
          </>
        )}
      </Card>
    </Modal>
  );
};
