import * as React from 'react';
import { Button, Card } from 'components';
import ReactCodeInput from 'react-verification-code-input';
import './withdrawal-confirm-modal.scss';
import {
  TwoFactorAuthSmsVerify,
  TwoFactorAuthSmsVerifyError,
} from 'core/domains/profile/two-factor-auth/two-factor-auth';
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { getIconPath } from 'utils/scripts/getIconPath';
import { Link, useNavigate } from 'react-router-dom';
import { copyClipboard } from 'utils/scripts/copyToClipboard';
import { digitSperator } from '../../../../../utils/scripts/exchange';
import moment from 'jalali-moment';

const { useState, useRef } = React;

type Props = {
  isTwoStepActive?: boolean | number;
  onConfirm: any;
  loading: boolean;
  stage: 'code' | 'thankyou';
  trackingCode: string;
  setLoading: any;
  withdrawalCommission?: number;
  isWaiting: boolean;
  amount?: number;
  cardNumber?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onResend?: any;
  symbol: string;
  networkFee?: any;
  onBack: any;
  marketName: string;
};

export const WithdrawalConfirm: React.FC<Props> = (props) => {
  const [fieldValue, setFieldValue] = useState<TwoFactorAuthSmsVerify>();
  const [fieldError, setFieldError] = useState<TwoFactorAuthSmsVerifyError>();
  const [countDownTime, setCountDownTime] = useState<number>(120);
  const codeRef = useRef<HTMLElement>(null);

  const navigate = useNavigate();

  const Head = (pr: any): React.ReactElement => {
    return (
      <div className="head">
        <div>
          <h4>{pr.title}</h4>
          <svg
            onClick={() => props.setIsOpen(false)}
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.0711 1.26477C11.29 0.483723 10.0237 0.483723 9.24264 1.26477L6.25006 4.25735L3.41421 1.42151C2.63316 0.640462 1.36683 0.640462 0.585786 1.42151C-0.195262 2.20256 -0.195262 3.46889 0.585786 4.24994L3.42163 7.08578L0.757359 9.75005C-0.0236896 10.5311 -0.0236896 11.7974 0.757359 12.5785C1.53841 13.3595 2.80474 13.3595 3.58579 12.5785L6.25006 9.91421L9.07107 12.7352C9.85212 13.5163 11.1184 13.5163 11.8995 12.7352C12.6805 11.9542 12.6805 10.6878 11.8995 9.90679L9.07848 7.08578L12.0711 4.0932C12.8521 3.31215 12.8521 2.04582 12.0711 1.26477Z"
              fill="#383952"
            />
          </svg>
        </div>
        {pr.subtitle && <p>{pr.subtitle}</p>}
      </div>
    );
  };

  return (
    <Card className="xpay-withdrawal-two-step-verify">
      {props.stage === 'code' ? (
        <>
          <div className="main code app-code">
            <div className="row col2">
              <div className="col">
                <span>نام ارز</span>
                <div>
                  <img src={getIconPath(props.symbol)} />
                  <span className="en">{props.symbol}</span>
                </div>
              </div>
            </div>
            <div className="row has-divider col2">
              <div className="col">
                <span>مقدار واحد</span>
                <div>
                  <span>
                    {digitSperator(props.amount)} {props.marketName}
                  </span>
                </div>
              </div>
              {props.withdrawalCommission && (
                <div className="col">
                  <span>کارمزد</span>
                  <div>
                    <span>
                      {digitSperator(props.withdrawalCommission)} {'تومان'}
                    </span>
                  </div>
                </div>
              )}{' '}
              {props.networkFee && (
                <div className="col">
                  <span>کارمزد</span>
                  <div>
                    <span>
                      {digitSperator(props.networkFee)} {props.symbol}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <p className="description">
              {props.isTwoStepActive
                ? 'کد ۶ رقمی نمایش داده شده در Authenticator را اینجا وارد نمایید.'
                : `کد ۶ رقمی اس ام اس شده به شماره ${localStorage.getItem(
                    'phone',
                  )} را وارد نمایید.`}
            </p>
            <ReactCodeInput
              className={`input ${fieldError?.phoneCode ? 'has-error' : ''}`}
              fieldWidth={38}
              values={fieldValue?.appCode ? fieldValue?.appCode.split('') : []}
              autoFocus={true}
              onChange={(value) => {
                setFieldValue((prev) => ({
                  ...prev,
                  appCode: value,
                }));
              }}
            />
            <div className="stats">
              <div className="timer">
                {countDownTime < 1 ? (
                  <span
                    className="resend"
                    onClick={() => {
                      props.onResend();
                      setCountDownTime(120);
                    }}
                  >
                    ارسال مجدد
                  </span>
                ) : (
                  <>
                    <CountdownCircleTimer
                      size={30}
                      isPlaying
                      duration={countDownTime || 0}
                      colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                      colorsTime={[7, 5, 2, 0]}
                      onComplete={() => setCountDownTime(0)}
                    >
                      {({ remainingTime }) => <span>{remainingTime}</span>}
                    </CountdownCircleTimer>{' '}
                    ثانیه تا ارسال مجدد کد
                  </>
                )}
              </div>
              <div className="errors">
                {fieldError?.phoneCode && (
                  <>
                    <QuestionCircleOutlined />
                    <span>{fieldError?.phoneCode}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="buttons">
            <Button
              type="info"
              disabled={props.loading || fieldValue?.appCode?.length !== 6}
              text=" ثبت سفارش"
              onClick={() =>
                props.onConfirm(
                  fieldValue?.appCode,
                  props.trackingCode,
                  setFieldError,
                )
              }
              loading={props.loading}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => props.setIsOpen(false)}
            />
          </div>
        </>
      ) : (
        <>
          <div className="thankyou-header">
            <div className="left-side">
              <img src="/assets/images/thankyou-success.svg" alt="" />
            </div>
          </div>
          <div className="main thank-you">
            <div className="row col2">
              <div className="col">
                <span>شناسه سفارش</span>
                <span>
                  <span className="en">{props.trackingCode}</span>
                  <CopyOutlined
                    onClick={() =>
                      copyClipboard(props.trackingCode, 'شناسه سفارش')
                    }
                  />
                </span>
              </div>
              <div className="col">
                <span>زمان پرداخت</span>
                <div>
                  <span>
                    {' '}
                    {moment().format('jYYYY/jM/jD')} ، ساعت{' '}
                    {moment().format('HH:mm')}
                  </span>
                </div>
              </div>
            </div>
            <div className="row has-divider col2">
              <div className="col">
                <span>مقدار</span>
                <div>
                  <span>
                    {digitSperator(props.amount)}
                    {'  ' + props.marketName}
                  </span>
                </div>
              </div>
            </div>
            <div className="buttons">
              <Link to="/dashboard/orders">
                <Button type={'info'} text={'مشاهده سوابق'} />
              </Link>
              <Button
                type={'outlined'}
                text={'بازگشت'}
                onClick={() => props.onBack()}
              />
            </div>
          </div>
        </>
      )}
    </Card>
  );
};
