import { DownOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';
import { Badge } from 'components/badge/badge';
import { Chart } from 'components/chart/chart';
import { Table } from 'components/table/table';
import {
  MarketFilterValues,
  PaginationList,
  PriceTableMarket,
} from 'core/domains';
import { useEffect, useState } from 'react';
import { toFixed, ToIranCurrency } from 'utils/scripts/decimal';
import './markets.scss';
import { getIconPath } from 'utils/scripts/getIconPath';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SortIcon } from 'assets/icons/orders/Sort.svg';
import { ReactComponent as Search } from 'assets/icons/wallet/Search.svg';
import { _dateSorts } from '../orders/my-orders/filters/constants';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ToItemTypes } from 'utils/scripts/table';
import { getPrices } from 'core/repositories';
import { TableColumn } from 'react-data-table-component';
import { Spinner } from '../../../components/spinner/spinner';

const empty_filters = {
  keywords: '',
  sort: {
    id: 0,
    title: 'مرتب سازی',
    icon: <SortIcon />,
  },
};

export const Markets: React.FC = () => {
  const [filterText, setFilterText] = useState('');
  const [markets, setMarkets] = useState<PaginationList<PriceTableMarket>>();
  const [filters, setFilters] = useState<MarketFilterValues>(empty_filters);
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<PriceTableMarket[]>();
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    filterHandle();
  }, []);

  const filterHandle = () => {
    let q = '';
    // q += `${q.includes('?') ? '&' : '?'}`;

    if (filters) {
      if (filters?.sort && filters?.sort.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}dateSort=${filters?.sort?.id}`;
      if (filters?.keywords)
        q += `${q.includes('?') ? '&' : '?'}keywords=${filters?.keywords}`;
    }

    getPrices(setCardIsLoading, setMarkets, { pageNumber: 1, pageSize: 10 }, q);
  };

  const clickOnSort: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      sort: _dateSorts.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const handleButtonClick = (side: string, symbol: string) => {
    navigate(`/dashboard/orders/create-order?side=${side}&symbol=${symbol}`);
  };

  const columns: TableColumn<PriceTableMarket>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row, rowIndex) => (rowIndex ? rowIndex + 1 : 0),
      width: '50px',
    },
    {
      name: 'نام ارز',
      id: 'name',
      cell: (row: PriceTableMarket) => (
        <span className="d-flex">
          <img src={getIconPath(row.symbol)} alt="" />
          <p className="market">
            {row.name}
            <span className="symbol">{row.symbol}</span>
          </p>
        </span>
      ),
    },
    {
      name: 'قیمت دلاری',
      id: 'last_usdt_price',
      selector: (row: PriceTableMarket) =>
        row.weeklyPriceLog
          ? toFixed(row.weeklyPriceLog[row.weeklyPriceLog.length - 1])
          : 1,
    },
    {
      name: 'قیمت خرید (تومان)',
      id: 'buyPrice',
      hide: 720,

      selector: (row: PriceTableMarket) => row.buyPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.buyPrice),
    },
    {
      name: 'قیمت فروش (تومان)',
      id: 'sellPrice',
      hide: 720,

      selector: (row: PriceTableMarket) => row.sellPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.sellPrice),
    },
    {
      name: 'تغییرات (24 ساعت)',
      id: 'change24h',
      // hide: 'md',
      selector: (row: PriceTableMarket) => row.changeOf24HourPercent,
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Badge
            text={`${row.changeOf24HourPercent.toFixed(2)}٪`}
            type={row.changeOf24HourPercent > 0.0 ? 'success' : 'error'}
          />
        ) : (
          '-'
        ),
    },
    {
      name: 'نمودار تغییرات (24 ساعت)',
      id: 'chart',
      hide: 720,
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Chart data={row.weeklyPriceLog} />
        ) : (
          <span>-</span>
        ),
    },
    {
      name: 'معامله',
      id: 'create-order',
      cell: (row: PriceTableMarket) => (
        <div className="currency-buttons">
          <Button
            className="buy"
            type="outlined"
            text="خرید"
            onClick={() => handleButtonClick('buy', row.symbol.toLowerCase())}
          />
          <Button
            className="sell"
            type="outlined"
            text="فروش"
            onClick={() => handleButtonClick('sell', row.symbol.toLowerCase())}
          />
        </div>
      ),
    },
  ];

  return (
    <Card className="xpay-markets-dashboard">
      <Spinner loading={cardIsLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <div className="right-side">
                <div className="field">
                  <Search />
                  <input
                    type="text"
                    value={filters?.keywords}
                    placeholder="نام و یا نماد رمز ارز مورد نظر خود را وارد کنید..."
                    className="search"
                    name="search"
                    onChange={(e) =>
                      setFilters({ ...filters, keywords: e.target.value })
                    }
                  />
                </div>

                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: ToItemTypes(_dateSorts),
                    onClick: clickOnSort,
                  }}
                  placement="bottom"
                >
                  <Button
                    icon={<DownOutlined />}
                    type="outlined"
                    text={filters?.sort?.title}
                  />
                </Dropdown>
              </div>

              <div className="buttons">
                <Button
                  className="search"
                  type="outlined"
                  text="اعمال فیلتر"
                  onClick={filterHandle}
                />
                <Button
                  className="clear-search"
                  type="outlined"
                  text="حذف فیلتر"
                  onClick={() => {
                    setFilters(empty_filters);
                    getPrices(
                      setCardIsLoading,
                      setMarkets,
                      { pageNumber: 1, pageSize: 10 },
                      '',
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {markets && !cardIsLoading && (
            <Table
              data={markets?.items}
              pagination={markets?.pagination}
              className="price-chart-table"
              column={columns}
              setData={setMarkets}
              hasFiltering={true}
              blur={cardIsLoading}
              setTableLoading={setCardIsLoading}
              getData={getPrices}
              query={query}
              // hasSearchbar
              // searchFilterItems={['symbol', 'name']}
            />
          )}
        </div>
      </Spinner>
      <div className="mobile-show">
        <div className="filter-row">
          <div className="fields">
            <div className="right-side">
              <div className="field">
                <Search />
                <input
                  type="text"
                  value={filters?.keywords}
                  placeholder="نام و یا نماد رمرز ارز مورد نظر خود را وارد کنید..."
                  className="search"
                  name="search"
                  onChange={(e) =>
                    setFilters({ ...filters, keywords: e.target.value })
                  }
                />
              </div>

              <Dropdown
                trigger={['click']}
                menu={{ items: ToItemTypes(_dateSorts), onClick: clickOnSort }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.sort?.title}
                />
              </Dropdown>
            </div>

            <div className="buttons">
              <Button
                className="search"
                type="outlined"
                text="اعمال فیلتر"
                onClick={filterHandle}
              />
              <Button
                className="clear-search"
                type="outlined"
                text="حذف فیلتر"
                onClick={() => {
                  setFilters(empty_filters);
                  getPrices(
                    setCardIsLoading,
                    setMarkets,
                    { pageNumber: 1, pageSize: 10 },
                    '',
                  );
                }}
              />
            </div>
          </div>
        </div>
        {markets?.items?.length &&
          markets.items.length > 0 &&
          (search === '' ? markets?.items : results)?.map((item) => (
            <Card blur={cardIsLoading} className="coin-card">
              <div>
                <div className="right">
                  <img src={getIconPath(item.symbol)} alt="" />
                  <p>{item.name}</p>
                  <span>{item.symbol}</span>
                </div>
                <div className="left">
                  <p>
                    {item.weeklyPriceLog
                      ? item.weeklyPriceLog[item.weeklyPriceLog.length - 1]
                      : '-'}
                    $
                  </p>
                </div>
              </div>
              <div>
                <p className="right">قیمت فروش:</p>
                <p className="left">{ToIranCurrency(item.buyPrice)} تومان</p>
              </div>
              <div>
                <p className="right">قیمت خرید:</p>
                <p className="left">{ToIranCurrency(item.sellPrice)} تومان</p>
              </div>
              <div>
                <div className="right">
                  <p>
                    تغییرات<span>(24h)</span>:
                  </p>
                  <span
                    className={`${
                      item.changeOf24HourPercent > 0 ? 'success' : 'danger'
                    }`}
                  >
                    {item.changeOf24HourPercent.toFixed(1)}٪
                  </span>
                </div>
                <div className="left">
                  {item.weeklyPriceLog ? (
                    <Chart data={item.weeklyPriceLog} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <Button
                onClick={() =>
                  navigate(
                    `/dashboard/orders/create-order?side=buy&symbol=${item.symbol.toLowerCase()}`,
                  )
                }
                text="خرید و فروش"
                type="info"
              />
            </Card>
          ))}
      </div>
    </Card>
  );
};
