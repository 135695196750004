export const SERVER_URL = 'https://api.xpay.co/v1/';
// export const SERVER_URL = 'https://api.xpay.co/v1/';
export const SITE_ADDRESS = 'www.XPAY.com';
export const SERVER_ADDRESS = 'https://api.xpay.co/';

export const SITE_URL = 'https://xpay.co';
export const APPNAME = 'ایکس پی';
export const Logo = '/LogoXpay.png';
export const Fav = '/fav.png';

export const SAMPLEAUTHPHOTOLevel2 =
  '/assets/images/auth/auth-samples/xpay/level2.jpg';
export const SAMPLEAUTHPHOTOLevel3 =
  '/assets/images/auth/auth-samples/xpay/level3.jpg';
export const MANIFEST = 'manifest-Xpay.json';
export const CHANNEL_ID = 'https://t.me/Xpay_ir';
export const TGBOT_ID = 'https://t.me/XpayExchange_bot';

export const ONLINE_CHAT = '/xpay-goftino.js';
export const GTM = '/gtm-xpay.js';

export const APP_VERSION = 'V5.8.7';

export const TERM_LINK = 'https://xpayex.org/terms/';
