import { Button, Card } from 'components';
import './popular-currencies-box.scss';
import { Table } from 'components/table/table';
import { useState, useEffect, useRef } from 'react';
import { API } from 'apis';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { getIconPath } from 'utils/scripts/getIconPath';
import { ToIranCurrency } from 'utils/scripts/decimal';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { ReactComponent as ArrowLeft } from 'assets/icons/main/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/main/ArrowRight.svg';

interface Currency {
  id: string;
  name: string;
  symbol: string;
  usdtPrice: number;
  buyPrice: number;
  sellPrice: number;
}

export const PopularCurrenciesBox = () => {
  // FOR MOBILE:
  const prevEl = useRef<HTMLDivElement>(null);
  const nextEl = useRef<HTMLDivElement>(null);

  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const getCurrencies = async () => {
    setLoading(true);
    await getViaAuth<Currency[]>(API.markets.priceList + '/1/10').then(
      (response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          setCurrencies(
            response.value.items.filter(
              (currency: Currency) =>
                currency.symbol.toUpperCase() !== 'PM' &&
                currency.symbol.toUpperCase() !== 'PM-U' &&
                currency.symbol.toUpperCase() !== 'PM' &&
                currency.symbol.toUpperCase() !== 'UUSD',
            ),
          );
        }
      },
    );
  };

  const handleButtonClick = (side: string, symbol: string) => {
    navigate(`/dashboard/orders/create-order?side=${side}&symbol=${symbol}`);
  };

  const columns: any[] = [
    {
      name: 'نام رمزارز',
      id: 'name',
      cell: (row: Currency) => (
        <span className="currency">
          <img width={24} height={24} src={getIconPath(row.symbol)} alt="" />
          <p>
            {row.name}
            <span className="symbol en">({row.symbol})</span>
          </p>
        </span>
      ),
    },
    {
      name: 'قیمت دلاری',
      id: 'usdtPrice',
      selector: (row: Currency) => row.usdtPrice,
    },
    {
      name: 'قیمت خرید (تومان)',
      id: 'buyPrice',
      selector: (row: Currency) => row.buyPrice,
      cell: (row: Currency) => ToIranCurrency(row.buyPrice),
    },
    {
      name: 'قیمت فروش (تومان)',
      id: 'sellPrice',
      selector: (row: Currency) => row.sellPrice,
      cell: (row: Currency) => ToIranCurrency(row.sellPrice),
    },
    {
      name: 'معامله',
      id: 'exchange',
      width: '25%',
      cell: (row: Currency) => (
        <div className="currency-buttons">
          <Button
            className="buy"
            type="outlined"
            text="خرید"
            onClick={() => handleButtonClick('buy', row.symbol.toLowerCase())}
          />
          <Button
            className="sell"
            type="outlined"
            text="فروش"
            onClick={() => handleButtonClick('sell', row.symbol.toLowerCase())}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <>
      {/* DESKTOP BEGIN */}
      <Card
        title="ارز های پرطرفدار"
        href="/dashboard/markets"
        hrefTitle="قیمت لحظه ای رمز ارز ها"
        className="popular-currencies-box desktop-show"
        divider
      >
        {currencies && (
          <Table
            blur={loading}
            hasFiltering={true}
            data={currencies}
            column={columns}
            setData={setCurrencies}
            getData={getCurrencies}
          />
        )}
      </Card>
      {/* DESKTOP END */}

      {/* MOBILE BEGIN */}
      <Card
        className="popular-currencies-slider mobile-show"
        title="ارز های پرطرفدار"
        href="/dashboard/markets"
        hrefTitle="قیمت لحظه ای رمز ارز ها"
        divider
      >
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: nextEl.current,
            prevEl: prevEl.current,
          }}
          slidesPerView={1}
          style={{ marginInline: 'auto' }}
        >
          {currencies &&
            currencies?.map((currency: Currency, index) => {
              return (
                <>
                  <SwiperSlide key={index}>
                    <div className="popular-currencies-content">
                      <div className="name-symbol">
                        <img
                          width={40}
                          height={40}
                          src={getIconPath(currency.symbol)}
                          alt=""
                        />
                        <h3>{currency.name}</h3>
                      </div>
                      <div className="symbol en">{currency.symbol}</div>
                      {currency.usdtPrice}
                      <div className="buy">
                        <span>{ToIranCurrency(currency.buyPrice)} تومان</span>
                        <p>قیمت خرید</p>
                      </div>
                      <div className="sell">
                        <span>{ToIranCurrency(currency.sellPrice)} تومان</span>
                        <p>قیمت فروش</p>
                      </div>
                      <Button
                        className="buy-button"
                        type="outlined"
                        text="خرید"
                        onClick={() =>
                          handleButtonClick(
                            'buy',
                            currency.symbol.toLowerCase(),
                          )
                        }
                      />
                      <Button
                        className="sell-button"
                        type="outlined"
                        text="فروش"
                        onClick={() =>
                          handleButtonClick(
                            'sell',
                            currency.symbol.toLowerCase(),
                          )
                        }
                      />
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
        </Swiper>
        <div ref={prevEl} className="next-navigation">
          <ArrowRight />
        </div>
        <div ref={nextEl} className="pre-navigation">
          <ArrowLeft />
        </div>
      </Card>
      {/* MOBILE END */}
    </>
  );
};
