import * as React from 'react';

import 'components/bottom-menu/bottom-menu.scss';
import BottomMenuItem from 'components/bottom-menu/bottom-menu-item/bottom-menu-item';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '../../utils/constants/common';

type Props = {
  items: MenuItem[];
};

export const BottomMenu: React.FC<Props> = (props) => {
  const location = useLocation();

  return (
    <div className="xpay-bottom-menu">
      {props.items.map((item, index) => (
        <BottomMenuItem
          key={`bottom-menu-item-${index}`}
          id={item.id}
          icon={item.icon}
          title={item.title}
          link={item.link}
          secondaryLink={item?.secondaryLink}
        />
      ))}
    </div>
  );
};
