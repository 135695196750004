import { Button, Card, Dropdown } from 'components';
import './authentication-two.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Close } from 'assets/icons/authentication/Close.svg';
import { ReactComponent as Continue } from 'assets/icons/authentication/Continue.svg';
import { ReactComponent as UploadImage } from 'assets/icons/authentication/UploadImage.svg';
import { ReactComponent as BlueDot } from 'assets/icons/authentication/BlueDot.svg';
import { ReactComponent as Preview } from 'assets/icons/authentication/Preview.svg';
import { ReactComponent as CongratulationsMask } from 'assets/masks/authentication/CongratulationsMask.svg';
import { ReactComponent as Tick } from 'assets/icons/authentication/Tick.svg';
import { ReactComponent as Retry } from 'assets/icons/authentication/Retry.svg';
import { AuthenticationStepper } from './authentication-stepper/authentication-stepper';
import { Divider, Upload, Image } from 'antd';
import ReactCodeInput from 'react-verification-code-input';
import { ToEn } from 'utils/scripts/decimal';
import cities from 'assets/constants/cities.json';
import provinces from 'assets/constants/provinces.json';
import { UploadProps } from 'antd/lib/upload';
import warning from 'assets/images/authentication/warning.png';
import {
  addAddressAndPostalCodeUpgradeToLevel2,
  checkForAddressAndPostalCode,
  checkForLandLine,
  checkForLevel,
  landLineUpgradeToLevel2,
  verifyLandLineUpgradeToLevel2,
} from 'core/repositories';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { toast } from 'react-toastify';
import { readProfile } from '../../../../core/repositories/profile/profile';
import moment from 'jalali-moment';

interface LevelTwoInfo {
  number: string;
  code: string;
  province: string | number;
  city: string | number;
  address: string;
  postalCode: string;
  documentImage?: any;
  selfieImage?: any;
}

export const AuthenticationTwo = () => {
  const navigate = useNavigate();
  const parsedLevel = JSON.parse(localStorage.getItem('level') ?? 'null');
  const [step, setStep] = useState<number>(-1);
  const [info, setInfo] = useState<LevelTwoInfo>({
    number: '',
    code: '',
    province: '',
    city: '',
    address: '',
    postalCode: '',
    documentImage: undefined,
    selfieImage: undefined,
  });
  const [timer, setTimer] = useState<number>(30);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [rejectionMessage, setRejectionMessage] = useState<string | null>();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    parsedLevel?.uniqueName?.id === 2 ? handleChecks() : navigate('/dashboard');
  }, []);

  const handleChecks = async () => {
    setIsChecking(true);
    await checkForLandLine(setStep);
    await checkForAddressAndPostalCode(setStep);
    await checkForLevel(setStep, setRejectionMessage);
    setIsChecking(false);
  };

  const handleLandLineSubmit = async () => {
    await landLineUpgradeToLevel2(
      setLoading,
      { landLine: info?.number },
      () => {
        setStep(2);
        setTimer(30);
      },
    );
  };

  const handleVerifyLandLine = async () => {
    await verifyLandLineUpgradeToLevel2(
      setLoading,
      { landLine: info?.number, otpCode: info?.code },
      () => setStep(3),
    );
  };

  const handleAddressAndPostalCodeSubmit = async () => {
    await addAddressAndPostalCodeUpgradeToLevel2(
      setLoading,
      {
        address:
          provinces.find((province) => province?.id === info?.province)?.title +
          ' - ' +
          cities.find((city) => city.id === info?.city)?.title +
          ' - ' +
          info?.address,
        postalCode: info?.postalCode,
      },
      () => {
        setStep(4);
      },
    );
  };

  const handleResendCode = async () => {
    await landLineUpgradeToLevel2(setLoading, { landLine: info?.number });
    setTimer(30);
  };

  const handleImagesSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    if (info.documentImage != '' && info.selfieImage != '') {
      formData.append('DocumentFile', info.documentImage);
      formData.append('SelfiePic', info.selfieImage);
    }

    await postViaAuth(API.level.uploadFilesUpgradeToLevelTwo, formData, true, {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded / total) * 100);
        console.log(`Upload Progress: ${progress}%`);
      },
    }).then((response: any) => {
      if (responseValidator(response.status)) setStep(6);
    });
    setLoading(false);
  };

  const handleTryAgain = (infoImageKey: keyof LevelTwoInfo) => {
    setInfo((prevInfo) => ({ ...prevInfo, [infoImageKey]: undefined }));
  };

  const handlePreviewClick = () => {
    setPreviewVisible(true);
  };

  const handlePreviewClose = () => {
    setPreviewVisible(false);
  };

  const documentDraggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: ({ file }) => {
      if (file) {
        setInfo((prevInfo) => ({ ...prevInfo, documentImage: file }));
        toast.success('عکس با موفقیت انتخاب شد');
      }
    },
  };

  const selfieDraggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: ({ file }) => {
      if (file) {
        setInfo((prevInfo) => ({ ...prevInfo, selfieImage: file }));
        toast.success('عکس با موفقیت انتخاب شد');
      }
    },
  };

  return (
    <>
      {step != 6 && step != 7 && (
        <Card
          blur={isChecking}
          title="ارتقا به سطح دو"
          className="xpay-authentication-two"
          button={
            <Close onClick={() => navigate('/dashboard/authentication')} />
          }
          divider
        >
          {step == -1 && (
            <div className="step-warning-content">
              <div className="bg">
                <img src={warning} />
                <span>هشــدار!</span>
              </div>
              <p>ممکنه کاسه ای زیر نیم کاسه باشه!</p>
              <p>
                اگر فردی از شما درخواست ثبت نام در سایت های ارز دیجیتال مانند
                ایکس پی را کرده و در قبال آن پیشنهاد دریافت حقوق و کارمزد را
                داده است، از پذیرفتن آن جدا خودداری کنید. چرا که این افراد قصد
                پولشویی از طریق حساب های بانکی شما دارند که در این صورت شما را
                درگیر مسائل پیچیده قضایی خواهد کرد. جهت دریافت اطلاعات بیشتر با
                پشتیبانی ایکس پی در تماس باشید.
              </p>
              <span>
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />{' '}
                متن و قوانین را مشاهده کردم و از موارد اشاره شده آگاه هستم
              </span>
              <Button
                type="primary"
                text="تایید قوانین و ادامه"
                disabled={!isCheckboxChecked}
                onClick={() => setStep(0)}
              />
            </div>
          )}
          {step == 0 && (
            <div className="step-agreement-content">
              <p>
                تایید این مرحله به منزله ی پذیرش تمامی قوانین است، آیا تایید
                میکنید؟
              </p>
              <div className="buttons">
                <Button
                  text="تایید قوانین و ادامه"
                  type="primary"
                  onClick={() => setStep(1)}
                />
                <Button
                  text="رد و بازگشت"
                  type="secondary"
                  onClick={() => setStep(-1)}
                />
              </div>
            </div>
          )}
          {step == 1 && (
            <>
              <AuthenticationStepper step={1} />
              <Divider className="step-divider" />
              <div className="step-content">
                <div className="field">
                  <input
                    maxLength={11}
                    placeholder="تلفن ثابت با کد شهر"
                    onChange={(e) =>
                      setInfo({ ...info, number: e.target.value })
                    }
                  />
                </div>
                <Button
                  loading={loading}
                  type="primary"
                  text="ثبت و دریافت کد"
                  icon={<Continue />}
                  disabled={loading || info.number.length < 11}
                  onClick={handleLandLineSubmit}
                />
              </div>
            </>
          )}
          {step == 2 && (
            <>
              <AuthenticationStepper step={1} />
              <Divider className="step-divider" />
              <div className="step-content">
                <div className="field">
                  <input value={info?.number} disabled />
                </div>
                <div className="row">
                  <p>
                    کد اعلام شده از طریق تماس به {info?.number} را وارد کنید:
                  </p>
                  <span onClick={() => setStep(1)}>تغییر</span>
                </div>
                <ReactCodeInput
                  className="telephone-code"
                  fieldWidth={60}
                  fieldHeight={60}
                  values={info?.code.split('')}
                  autoFocus={true}
                  fields={4}
                  onChange={(value) => {
                    setInfo({ ...info, code: ToEn(value) });
                  }}
                />
                {timer > 0 ? (
                  <p id="waiting">{timer} ثانیه تا ارسال مجدد کد</p>
                ) : (
                  <p id="resend" onClick={() => handleResendCode()}>
                    ارسال مجدد کد
                  </p>
                )}
                <Button
                  type="primary"
                  text="ثبت و ادامه"
                  disabled={loading || info?.code.length !== 4}
                  loading={loading}
                  icon={<Continue />}
                  onClick={handleVerifyLandLine}
                />
              </div>
            </>
          )}
          {step == 3 && (
            <>
              <AuthenticationStepper step={2} />
              <Divider className="step-divider" />
              <div className="step-content">
                <div className="province-city">
                  <Dropdown
                    items={provinces}
                    onChangeHandle={(selectedProvinceId) => {
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        province: selectedProvinceId,
                      }));
                    }}
                  />
                  <Dropdown
                    items={
                      info?.province && cities
                        ? cities?.filter(
                            (city) => city?.province_id === info?.province,
                          )
                        : [{ id: 0, title: 'انتخاب شهر' }]
                    }
                    onChangeHandle={(selectedCityId) => {
                      setInfo({ ...info, city: selectedCityId });
                    }}
                  />
                </div>
                <textarea
                  className="address"
                  placeholder="آدرس محل سکونت"
                  value={info?.address}
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      address: e.target.value,
                    })
                  }
                />
                <div className="field">
                  <input
                    placeholder="کد پستی محل سکونت"
                    maxLength={12}
                    onChange={(e) =>
                      setInfo({ ...info, postalCode: e.target.value })
                    }
                  />
                </div>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={
                    loading ||
                    info.postalCode === '' ||
                    info.address === '' ||
                    info.province === '' ||
                    info.city === ''
                  }
                  text="ثبت و ادامه"
                  icon={<Continue />}
                  onClick={handleAddressAndPostalCodeSubmit}
                />
              </div>
            </>
          )}
          {step == 4 && (
            <>
              <AuthenticationStepper step={3} />
              <Divider className="step-divider" />
              <div className="step-content">
                <p>
                  متن زیر را <span id="similar">همانند نمونه عکس</span> روی کاغذ
                  بنویسید و در کنار کارت‌شناسایی خود قرار دهید و یک تصویر واضح و
                  خوانا بگیرید:{' '}
                  <p id="small">
                    ( کارت ملی، کارت پایان خدمت یا شناسنامه ای که کد ملی روی آن
                    درج شده )
                  </p>
                </p>
                <div className="requirements">
                  <div className="item">
                    <BlueDot /> <span>تصویر اسکن شده نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر بریده و ناقص نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>انعکاس نور وجود نداشته باشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر تار و ناخوانا نباشد.</span>
                  </div>
                </div>
                <p id="handwritten">متن دست نوشته:</p>
                <div className="handwritten-prototype">
                  <p>
                    اینجانب {readProfile()?.fullName} به کد ملی ۰۱۲۳***۷۸۹ در
                    تاریخ {moment().locale('fa').format('jYYYY/jMM/jDD')} ضمن
                    مطالعه و‌ اعلام قبولی نسبت به مقررات مربوط به تایید هویت و
                    موافقتنامه استفاده از خدمات، که در قسمت قوانین سایت ایکس پی
                    قرار گرفته است تعهد میدهم که مدارک و حساب کاربری خود را به
                    منظور خرید و فروش ارزهای دیجیتال یا دیگر خدمات ایکس پی در
                    اختیار سایر افراد قرار ندهم، در غیر این صورت، مسئولیت جبران
                    هرگونه خسارت ناشی از این امر را بر عهده خواهم گرفت. نام و
                    نام‌خانوادگی، امضا و تاریخ
                  </p>
                </div>
                <div className="image-upload">
                  <Upload.Dragger {...documentDraggerProps}>
                    <>
                      {info.documentImage && (
                        <img
                          src={URL.createObjectURL(info.documentImage)}
                          alt="Uploaded"
                          height={160}
                        />
                      )}
                      {!info.documentImage && (
                        <div>
                          <UploadImage />
                          <p>افزودن تصویر</p>
                        </div>
                      )}
                    </>
                  </Upload.Dragger>
                </div>
                {info.documentImage && (
                  <p id="retry" onClick={() => handleTryAgain('documentImage')}>
                    انتخاب مجدد <Retry />
                  </p>
                )}
                <Button
                  type="primary"
                  className="preview"
                  text="مشاهده نمونه عکس"
                  icon={<Preview />}
                  onClick={handlePreviewClick}
                />
                <Image
                  style={{ display: 'none' }}
                  src={info.documentImage}
                  preview={{
                    visible: previewVisible,
                    src: info?.documentImage
                      ? URL.createObjectURL(info?.documentImage)
                      : info?.documentImage,
                    onVisibleChange: handlePreviewClose,
                  }}
                />
                <Button
                  className="image-submit"
                  type="primary"
                  text="ثبت و ادامه"
                  icon={<Continue />}
                  disabled={!info.documentImage}
                  onClick={() => setStep(5)}
                />
              </div>
            </>
          )}
          {step == 5 && (
            <>
              <AuthenticationStepper step={4} />
              <Divider className="step-divider" />
              <div className="step-content">
                <p>
                  متن زیر را <span id="similar">همانند نمونه عکس</span> روی کاغذ
                  بنویسید و همراه کارت‌شناسایی در کنار چهره خود قرار دهید و یک
                  سلفی واضح و خوانا از خود بگیرید:
                </p>
                <div className="requirements">
                  <div className="item">
                    <BlueDot /> <span>تصویر اسکن شده نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر بریده و ناقص نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>انعکاس نور وجود نداشته باشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر تار و ناخوانا نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot />{' '}
                    <span>بدون عینک آفتابی و ماسک تصویربرداری شود.</span>
                  </div>
                </div>
                <p id="handwritten">متن دست نوشته:</p>
                <div className="handwritten-prototype">
                  <p>
                    اینجانب {readProfile()?.fullName} به کد ملی ۰۱۲۳***۷۸۹ در
                    تاریخ {moment().locale('fa').format('jYYYY/jMM/jDD')} ضمن
                    مطالعه و‌ اعلام قبولی نسبت به مقررات مربوط به تایید هویت و
                    موافقتنامه استفاده از خدمات، که در قسمت قوانین سایت ایکس پی
                    قرار گرفته است تعهد میدهم که مدارک و حساب کاربری خود را به
                    منظور خرید و فروش ارزهای دیجیتال یا دیگر خدمات ایکس پی در
                    اختیار سایر افراد قرار ندهم، در غیر این صورت، مسئولیت جبران
                    هرگونه خسارت ناشی از این امر را بر عهده خواهم گرفت. نام و
                    نام‌خانوادگی، امضا و تاریخ
                  </p>
                </div>
                <div className="image-upload">
                  <Upload.Dragger {...selfieDraggerProps}>
                    <>
                      {info.selfieImage && (
                        <img
                          src={URL.createObjectURL(info.selfieImage)}
                          alt="Uploaded"
                          height={160}
                        />
                      )}
                      {!info.selfieImage && (
                        <div>
                          <UploadImage />
                          <p>افزودن تصویر</p>
                        </div>
                      )}
                    </>
                  </Upload.Dragger>
                </div>
                {info.selfieImage && (
                  <p id="retry" onClick={() => handleTryAgain('selfieImage')}>
                    انتخاب مجدد <Retry />
                  </p>
                )}
                <Button
                  type="primary"
                  className="preview"
                  text="مشاهده نمونه عکس"
                  icon={<Preview />}
                  onClick={handlePreviewClick}
                />
                <Image
                  style={{ display: 'none' }}
                  src={info.selfieImage}
                  preview={{
                    visible: previewVisible,
                    src: info?.selfieImage
                      ? URL.createObjectURL(info?.selfieImage)
                      : info?.selfieImage,
                    onVisibleChange: handlePreviewClose,
                  }}
                />
                <Button
                  className="image-submit"
                  type="primary"
                  text="ثبت و ادامه"
                  icon={<Continue />}
                  loading={loading}
                  disabled={loading || !info.documentImage || !info.selfieImage}
                  onClick={() => handleImagesSubmit()}
                />
              </div>
            </>
          )}
        </Card>
      )}
      {step == 6 && (
        <Card className="xpay-authentication-two congratulations">
          <div className="step-congratulations">
            <CongratulationsMask id="mask" />
            <Tick id="tick" />
            <div className="step-content">
              <h5>تبریک، با موفقیت احراز هویت رو کامل کردی!</h5>
              <p>
                مدارک و هویت شما توسط کارشناسان ایکس پی بررسی خواهد شد و پس از
                تایید و اطلاع به شما، می توانید به تمامی امکانات ایکس پی دسترسی
                داشته باشید.
              </p>
              <Button
                type="primary"
                text="انتقال به صفحه اصلی"
                icon={<Continue />}
                onClick={() => navigate('/dashboard')}
              />
            </div>
          </div>
        </Card>
      )}
      {step === 7 && (
        <Card className="xpay-authentication-two rejected">
          <div className="step-rejected">{rejectionMessage}</div>
          <button onClick={() => setStep(4)}>Go To Pic Step</button>
        </Card>
      )}
    </>
  );
};
