import * as React from 'react';
import './two-step-verify.scss';
import { QrCode } from 'core/domains/profile/profile';
import { RecoveryCodesAuthenticator } from './stages/recovery';
import { DownloadGoogleAuthenticator } from './stages/download';
import { OtpCode } from './stages/code';
import { QrCodeGoogleAuthenticator } from './stages/qrcode';
import { Phone } from './stages/phone';
import { TwoFactor } from 'core/domains/profile/two-factor-auth/two-factor-auth';
import { useEffect } from 'react';

const { useState, useRef } = React;

type Props = {
  state: TwoFactor;
  selectedOtp?: 'sms' | 'google';
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phone?: string;
  twoFaStage: 'PHONE' | 'DOWNLOAD' | 'QRCODE' | 'CODE' | 'RECOVERY';
  setTwoFaStage: React.Dispatch<
    React.SetStateAction<'PHONE' | 'DOWNLOAD' | 'QRCODE' | 'CODE' | 'RECOVERY'>
  >;
  getdata: () => any;
};

export const TwoStepVerify: React.FC<Props> = (props) => {
  const [qrCode, setQrCode] = useState<QrCode>();

  React.useEffect(() => {
    props.twoFaStage === 'RECOVERY' && props.getdata();
  }, [props.twoFaStage]);

  function renderStage() {
    switch (props.twoFaStage) {
      case 'PHONE':
        return (
          <Phone
            setStage={props.setTwoFaStage}
            setIsOpen={props.setIsOpen}
            isActive={
              (props.selectedOtp === 'sms' &&
                props.state.isActive &&
                props.state.type.id === 1) ||
              (props.selectedOtp === 'google' &&
                props.state.isActive &&
                props.state.type.id === 2)
            }
            phone={props.phone}
            type={props.selectedOtp}
          />
        );
      case 'DOWNLOAD':
        return <DownloadGoogleAuthenticator setStage={props.setTwoFaStage} />;
      case 'QRCODE':
        return (
          <QrCodeGoogleAuthenticator
            qrCode={qrCode}
            setQrCode={setQrCode}
            setStage={props.setTwoFaStage}
          />
        );
      case 'CODE':
        return (
          <OtpCode
            isActive={
              (props.selectedOtp === 'sms' &&
                props.state.isActive &&
                props.state.type.id === 1) ||
              (props.selectedOtp === 'google' &&
                props.state.isActive &&
                props.state.type.id === 2)
            }
            setStage={props.setTwoFaStage}
          />
        );
      case 'RECOVERY':
        return (
          <RecoveryCodesAuthenticator
            setStage={props.setTwoFaStage}
            qrCode={qrCode}
            setIsOpen={props.setIsOpen}
          />
        );
      default:
        return <>invalid stage</>;
    }
  }

  return renderStage();
};
