import './google-authentication.scss';
import { Button, Card, Input, Modal } from 'components';
import { Circle } from './circle/circle';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Divider, message } from 'antd';
import ReactCodeInput from 'react-verification-code-input';
import * as React from 'react';
import {
  faNumToEnNum,
  IsNumeric,
  ToEn,
} from '../../../../utils/scripts/decimal';
import {
  copyClipboard,
  copyToClipboard,
} from '../../../../utils/scripts/copyToClipboard';
import { CopyOutlined } from '@ant-design/icons';
import { ReactComponent as AppStoreIcon } from 'assets/icons/profile/apple.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/profile/google.svg';
import {
  getQrCodeOtp,
  setupGoogleAuth,
} from '../../../../core/repositories/profile/profile';
import success from 'assets/images/profile/check.png';
import union from 'assets/images/profile/union.png';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

type VerifyCodes = {
  googleAuthCode: string;
  code: string;
};

export interface QrCode {
  recoveryCodes: string;
  qrCodeUrl: string;
  authenticatorKey: string;
}

export const GoogleAuthentication: React.FC<Props> = (props) => {
  const [twoStepButtonLoading, setTwoStepButtonLoading] = useState(false);
  const [verifyCodeValues, setVerifyCodeValues] = useState<VerifyCodes>();
  const [googleCode, setGoogleCode] = useState('');
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCode, setQRCode] = useState<QrCode>();
  const [qrLoading, setQRLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>();

  const goToNextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };

  const goToPreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onCodeChange = (value: string, field: string) => {
    if (value !== '') value = faNumToEnNum(value);

    if (value !== '' && !IsNumeric(value.split('')[value.split('').length - 1]))
      return;

    // setVerifyCodeValues({
    //   ...verifyCodeValues,
    //   [field]: value,
    // });
  };

  const handleVerify = () => {
    setupGoogleAuth(setTwoStepButtonLoading, googleCode!, () => setStep(5));
  };

  const onHandleQR = () => {
    getQrCodeOtp(setQRLoading, setQRCode, goToNextStep);
  };

  // const onSetupTwoStepClick = () =>
  // setupTwoStep(setTwoStepButtonLoading, setTwoStepData, goToNextStep);

  // const onVerifySetupTwoStepClick = () =>
  //   verifyCodeValues.googleAuthCode.length === 6 &&
  //   verifySetupTwoStep(
  //     setTwoStepButtonLoading,
  //     { code: verifyCodeValues?.googleAuthCode },
  //     () => {
  //       goToNextStep();
  //     },
  //   );
  //
  // const onDisableTwoStepClick = () => {
  //   disableTwoStep(setTwoStepButtonLoading, { code: googleCode }, () => {
  //     message.success('تایید دو مرحله ای با موفقیت غیر فعال شد.');
  //     setStep(0);
  //   });
  // };

  return (
    <Modal
      className="xpay-google-authentication"
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={'احراز هویت دو مرحله ای'}
      closeAble
    >
      <Divider className={'modal-divider'} />
      {/* Start Step 1 */}
      {step === 1 && (
        <Card className="step-1">
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" />
            <hr />
            <Circle centerText="3" bottomText="تهیه پشتیبان" />
            <hr />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <p>
            {' '}
            جهت فعالسازی ابتدا نرم‌افزار Google Authenticator را دانلود نمایید.
          </p>
          <section>
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer"
            >
              <span className={'en'}>App Store</span> <AppStoreIcon />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <span className={'en'}>Google Play</span> <GoogleIcon />
            </a>
          </section>
          <div className={'buttons'}>
            <Button
              type={'primary'}
              text="مرحله ی بعد"
              onClick={onHandleQR}
              loading={qrLoading}
              disabled={qrLoading}
            />
            <Button
              type={'outlined'}
              text={'انصراف'}
              onClick={() => props.setIsOpen(false)}
            />
          </div>
        </Card>
      )}
      {/* End Step 1 */}
      {/* Start Step 2 */}
      {step === 2 && (
        <Card className="step-2" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" />
            <hr />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <section>
            <div className="qr-code">
              <QRCodeSVG
                style={{ margin: '16px 0' }}
                value={qrCode?.qrCodeUrl ?? ''}
              />
            </div>
            <p>
              لطفا بارکد را با تلفن همراه در نرم افزار Google Authenticator اسکن
              کنید. اگر امکان اسکن توسط گوشی برای شما مهیا نیست، می‌توانید کد
              زیر را به صورت دستی در آن وارد نمایید.
            </p>
          </section>
          <span id="key-link" className="en">
            {qrCode?.authenticatorKey}{' '}
            <CopyOutlined
              onClick={() =>
                copyClipboard(
                  qrCode?.authenticatorKey ?? '',
                  'کد Authenticator Key',
                )
              }
            />
          </span>
          <div className="buttons">
            <Button
              type={'primary'}
              text="مرحله ی بعد"
              onClick={goToNextStep}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading}
            />
            <Button
              type={'outlined'}
              text="انصراف"
              onClick={(step) => setStep(1)}
            />
          </div>
        </Card>
      )}

      {/* End Step 2 */}
      {/* Start Step 3 */}
      {step === 3 && (
        <Card className="step-3" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" reached />
            <hr className="reached" />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <p>
            لطفا این رمز عبور را روی کاغذ نوشته و در جای امنی نگه‌داری
            نمایید.این کد در زمان حذف شدن نرم‌افزار و یا سرقت گوشی شما به
            بازگرداندن اکانت شما کمک خواهد کرد.
          </p>
          <div
            className="code"
            onClick={() =>
              copyToClipboard(qrCode?.recoveryCodes.join(' '), 'کد امنیتی')
            }
          >
            <span className="en">{qrCode?.recoveryCodes.join(' ')}</span>{' '}
            <CopyOutlined />
          </div>
          <div className="buttons">
            <Button
              type={'primary'}
              text="مرحله ی بعد"
              onClick={goToNextStep}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading}
            />
            <Button
              type={'outlined'}
              text="انصراف"
              onClick={goToPreviousStep}
            />
          </div>
        </Card>
      )}

      {/* End Step 3 */}
      {/* Start Step 4 */}
      {step === 4 && (
        <Card className="step-4" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" reached />
            <hr className="reached " />
            <Circle centerText="4" bottomText="ثبت نهایی" reached />
          </div>
          <Divider className="card-divider" />
          <p>
            {' '}
            برداشت و واریز وجه جهت حفظ امنیت اکانت شما پس از انجام این مرحله تا
            ۲۴ ساعت قفل خواهد شد.{' '}
          </p>
          <div className="fields">
            <div className="column">
              <ReactCodeInput
                className={`code-input`}
                fieldWidth={80}
                fieldHeight={80}
                values={googleCode.split('')}
                autoFocus={true}
                onChange={(value) => {
                  setGoogleCode(ToEn(value));
                }}
                fields={6}
              />
            </div>
          </div>
          <div className="buttons">
            <Button
              type={'primary'}
              text="ثبت کد"
              onClick={handleVerify}
              loading={twoStepButtonLoading}
              disabled={!googleCode || googleCode.length !== 6}
            />
            <Button
              type={'outlined'}
              text="انصراف"
              onClick={goToPreviousStep}
            />
          </div>
        </Card>
      )}
      {/* End Step 4 */}
      {/* Start Step 5 */}
      {step === 5 && (
        <Card className="step-5" divider>
          <div className="bg">
            <div className="image-bg">
              <img alt="Success" src={success}></img>
              <img alt="Union" src={union} className={'union'}></img>
            </div>
          </div>

          <h4>ورود دو مرحله ای با موفقیت برای شما فعال شد.</h4>
          <span>
            از طریق گزینه ی زیر میتوانید نسبت به غیر فعال سازی تایید دو مرحله ای
            حساب خود اقدام نمایید.
          </span>
          <Divider className="card-divider" />
          <Button
            type={'primary'}
            text="متوجه شدم"
            onClick={() => props.setIsOpen(false)}
          />
        </Card>
      )}
      {/* End Step 5 */}
      {/* Start Step 6 */}
      {step === 6 && (
        <Card className="step-6" divider>
          <h4>غیر فعال کردن تایید دو مرحله ای</h4>
          <span>
            جهت غیر فعال کردن تایید دو مرحله ای، کد 6 رقمی گوگل را وارد نمایید.
          </span>

          <ReactCodeInput
            className={`code-input`}
            fieldWidth={80}
            fieldHeight={80}
            values={googleCode.split('')}
            autoFocus={true}
            onChange={(value) => {
              setGoogleCode(ToEn(value));
            }}
            fields={6}
          />

          <div className="buttons">
            <Button
              type={'primary'}
              text="ثبت کد"
              // onClick={onDisableTwoStepClick}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading || googleCode.length !== 6}
            />
            <Button
              type={'outlined'}
              text="بازگشت"
              onClick={() => setStep(5)}
            />
          </div>
        </Card>
      )}
      {/* End Step 6 */}
    </Modal>
  );
};
