import { API } from 'apis';
import { Market, MarketAllPrices, PriceTableMarket } from 'core/domains';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { Page } from '../../domains/pagging/pagging';

export const getAllFiats = (setLoader: any, setResponse: any): any => {
  setLoader(true);
  getViaAuth(API.fiats.getAll).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value);
  });
};

export const getAllMarkets = (setLoader: any, setResponse: any): any => {
  setLoader(true);
  getViaAuth(API.markets.getAll + '/1/999').then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value.items);
  });
};

export const getAllCryptoMarkets = (setLoader: any, setResponse: any): any => {
  setLoader(true);
  getViaAuth(API.markets.getAll + '/1/999').then((response: any) => {
    setLoader(false);
    responseValidator(response.status) &&
      setResponse(
        response.value.items.filter(
          (s: Market) => s.symbol !== 'IRT' && s.symbol !== 'PM',
        ),
      );
  });
};

export const getAllNetworks = (
  setLoader: any,
  setResponse: any,
  symbol: string,
  isDeposit: boolean,
): any => {
  setLoader(true);
  getViaAuth(API.markets.getAllNetworks + `${symbol}/${isDeposit}`).then(
    (response: any) => {
      setLoader(false);
      responseValidator(response.status) && setResponse(response.value);
    },
  );
};

export const getAllMarketsPrices = (
  setLoader: any,
  setResponse: any,
  page: number,
  size: number,
) => {
  setLoader(true);
  getViaAuth<MarketAllPrices>(
    API.markets.getAllPrices + `${page}/${size}`,
  ).then((response: any) => {
    console.log(response.value);
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value);
  });
};

export const getAllOrderMarketNameIds = (
  setLoader: any,
  setResponse: any,
  page: number,
  size: number,
): any => {
  setLoader(true);
  getViaAuth(API.markets.getAll + `/${page}/${size}`).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value.items);
  });
};

export const getAllDepositCryptoMarketNameIds = (
  setLoader: any,
  setResponse: any,
) => {
  setLoader(true);
  getViaAuth(API.markets.getAll + `/1/999`).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) &&
      setResponse(
        response.value.items.filter(
          (s: any) => s.symbol !== 'PM' && s.symbol !== 'IRT',
        ),
      );
  });
};

export const getDepositInfo = (
  setLoader: any,
  symbol: string,
  setNetworks: any,
) => {
  setLoader(true);
  getViaAuth(API.deposit.crypto.networksAndWallets + symbol).then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        response.value && setNetworks(response.value.addressWallets);
      }
    },
  );
};

export const getAllMarketNameIds = (
  setLoader: any,
  setResponse: any,
  page: number,
  size: number,
): any => {
  setLoader(true);
  getViaAuth(API.markets.getAll + `/${page}/${size}`).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value.items);
  });
};

export const getMineBalance = (setLoader: any, setResponse: any): any => {
  setLoader(true);
  getViaAuth(API.wallet.assets).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setResponse(response.value);
  });
};

export const getMineBalanceByPair = (
  pair: string,
  setLoader: any,
  setResponse: any,
) => {
  setLoader(true);
  getViaAuth(API.wallet.pairBalance + pair).then((response: any) => {
    setLoader(false);

    responseValidator(response.status) && setResponse(response.value);
  });
};

export const getAllCommissions = (setLoader: any, setData: any) => {
  setLoader(true);
  getViaAuth(API.siteSettings.commissions).then((response: any) => {
    setLoader(false);
    responseValidator(response.status) && setData(response.value.items);
  });
};

export const getPrices = (
  setLoader: any,
  setData: any,
  page: Page,
  query: string,
) => {
  setLoader(true);
  getViaAuth(
    API.markets.priceList + `/${page.pageNumber}/${page.pageSize}` + query,
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setData(response.value);
    }
  });
};
