import * as React from 'react';
import './xpay.scss';
import './../styles/antd.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from 'routes/dashboard/dashboard';
import { BankAccount } from 'routes/dashboard/bank-account/bank-account';
import Main from 'routes/dashboard/main/main';
import { Markets } from 'routes/dashboard/markets/markets';
import { Authentication } from 'routes/dashboard/authentication/authentication';
import CreateOrder from 'routes/dashboard/orders/create/create-order';
import { Wallet } from 'routes/dashboard/wallet/wallet';
import { Profile } from 'routes/dashboard/profile/profile';
import MyOrders from 'routes/dashboard/orders/my-orders/my-orders';
import { Referral } from './dashboard/referral/referral';
import { IrtTransactions } from './dashboard/orders/my-orders/irt-transactions/irt-transactions';
import { isLogin } from '../utils/scripts/api';
import { CryptoTransactions } from './dashboard/orders/my-orders/crypto-transactions/crypto-transactions';
import { ReferralPurchase } from './dashboard/referral/referral-purchase/referral-purchase';
import { ReferralActionsHistory } from './dashboard/referral/referral-actions-history/referral-actions-history';
import { ThankYouIrt } from './dashboard/wallet/deposit/currency/ThankYouPage/thank-you-irt';
import { BuyPerfectMoneyThankYouPage } from './dashboard/wallet/perfect-money/buy/thankyou/buy-pm-thankyou';
import { PmTransactions } from './dashboard/orders/my-orders/pm-transactions/pm-transactions';
import { MyTrades } from './dashboard/orders/my-orders/trade/my-trades';
import { NotificationsPage } from './dashboard/notifications/notifications';
import { AuthenticationOne } from './dashboard/authentication/authentication-one/authentication-one';
import { AuthenticationTwo } from './dashboard/authentication/authentication-two/authentication-two';
import { AddCard } from './dashboard/bank-account/add-card/add-card';
import { AddIBan } from './dashboard/bank-account/add-iban/add-iban';
import { NavigationPage } from 'components/navigation/navigation';
import { OrdersNavigationPage } from 'components/orders-navigation/orders-navigation';
import { IRTDeposit } from './dashboard/wallet/irt-deposit/irt-deposit';
import { IRTWithdrawal } from './dashboard/wallet/irt-withdrawal/irt-withdrawal';
import { CryptoWithdrawal } from './dashboard/wallet/crypto-withdrawal/crypto-withdrawal';
import { CryptoDeposit } from './dashboard/wallet/crypto-deposit/crypto-deposit';
import { Login } from './pages/login/login';
import { WalletNavigationPage } from '../components/wallet-navigation/wallet-navigation';
import { Register } from './pages/register/register';
import { PasswordRecovery } from './pages/password-recovery/password-recovery';

export const Xpay: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard">
          <Route
            path=""
            element={<Dashboard title="داشبورد" component={<Main />} />}
          />
          <Route
            path="bank-accounts"
            element={
              <Dashboard title="حساب های بانکی" component={<BankAccount />} />
            }
          />
          <Route
            path="bank-accounts/add-card"
            element={
              <Dashboard title="افزودن شماره کارت" component={<AddCard />} />
            }
          />
          <Route
            path="bank-accounts/add-iban"
            element={
              <Dashboard title="افزودن شماره شبا" component={<AddIBan />} />
            }
          />
          <Route
            path="markets"
            element={<Dashboard title="قیمت لحظه ای" component={<Markets />} />}
          />
          <Route path="orders">
            <Route
              path=""
              element={<Dashboard title="سفارش ها" component={<MyOrders />} />}
            />
            <Route
              path="create-order"
              element={
                <Dashboard title="خرید و فروش" component={<CreateOrder />} />
              }
            />
            <Route
              path="trade"
              element={
                <Dashboard title="سوابق سفارشات" component={<MyTrades />} />
              }
            />
            <Route
              path="irt"
              element={
                <Dashboard
                  title="سوابق ریالی"
                  component={<IrtTransactions />}
                />
              }
            />
            <Route
              path="currency"
              element={
                <Dashboard
                  title="سوابق ریالی"
                  component={<CryptoTransactions />}
                />
              }
            />
            <Route
              path="pm"
              element={
                <Dashboard
                  title="سوابق پرفکت مانی"
                  component={<PmTransactions />}
                />
              }
            />
            <Route
              path="friends-purchase"
              element={
                <Dashboard
                  title="سوابق رفرال"
                  component={<ReferralPurchase />}
                />
              }
            />
          </Route>

          <Route path="pm">
            <Route
              path="verify/:trackingCode"
              element={
                <Dashboard
                  title="نتیجه خرید پرفکت مانی"
                  component={
                    <BuyPerfectMoneyThankYouPage depositFromWallet={false} />
                  }
                />
              }
            />
          </Route>
          <Route path="wallet">
            <Route
              path=""
              element={<Dashboard title="کیف پول" component={<Wallet />} />}
            />
            موجودی فعلی شما:
            <Route
              path={'deposit'}
              element={
                <Dashboard title="واریز" component={<CryptoDeposit />} />
              }
            />
            <Route
              path={'withdrawal'}
              element={
                <Dashboard title="برداشت" component={<CryptoWithdrawal />} />
              }
            />
            <Route
              path={'deposit/:symbol'}
              element={
                <Dashboard title="واریز" component={<CryptoDeposit />} />
              }
            />
            <Route
              path={'withdrawal/:symbol'}
              element={
                <Dashboard title="برداشت" component={<CryptoWithdrawal />} />
              }
            />
            <Route
              path="deposit/IRT"
              element={<Dashboard title="واریز" component={<IRTDeposit />} />}
            />
            <Route
              path="withdrawal/IRT"
              element={
                <Dashboard title="برداشت" component={<IRTWithdrawal />} />
              }
            />
            <Route
              path="deposit/IRT/verify/:trackingCode"
              element={<Dashboard title="واریز" component={<ThankYouIrt />} />}
            />
          </Route>

          <Route
            path="referral"
            element={<Dashboard title="دعوت دوستان" component={<Referral />} />}
          />

          <Route
            path="authentication"
            element={
              <Dashboard title="احراز هویت" component={<Authentication />} />
            }
          />

          <Route
            path="authentication/one"
            element={
              <Dashboard
                title="ارتقا به سطح یک"
                component={<AuthenticationOne />}
              />
            }
          />

          <Route
            path="authentication/two"
            element={
              <Dashboard
                title="ارتقا به سطح دو"
                component={<AuthenticationTwo />}
              />
            }
          />

          <Route
            path="notifications"
            element={
              <Dashboard title="نوتیفیکیشن" component={<NotificationsPage />} />
            }
          />

          <Route
            path="profile"
            element={<Dashboard title="تنظیمات" component={<Profile />} />}
          />

          <Route
            path="navigation"
            element={<Dashboard title="منو" component={<NavigationPage />} />}
          />

          <Route
            path="orders-navigation"
            element={
              <Dashboard title="سوابق" component={<OrdersNavigationPage />} />
            }
          />

          <Route
            path="wallet-navigation"
            element={
              <Dashboard title="کیف پول" component={<WalletNavigationPage />} />
            }
          />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />

        <Route
          path="/"
          element={<Dashboard title="داشبورد" component={<Main />} />}
        />
      </Routes>
    </BrowserRouter>
  );

  function RequireAuth({ children }: any) {
    const isAuthenticated = isLogin();
    return isAuthenticated ? children : <Navigate to="/login" />;
  }
};
