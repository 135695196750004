import './login.scss';
import { phoneRegex } from '../../../utils/constants/constants';
import { post, responseValidator } from '../../../utils/scripts/api';
import { API } from '../../../apis';
import moment from 'moment/moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Enum } from '../../../core/domains';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/icons/main/Back.svg';
import { ReactComponent as CallIcon } from 'assets/icons/main/Call.svg';
import { ReactComponent as LockIcon } from 'assets/icons/main/Lock.svg';
import { Input } from 'antd';
import { faNumToEnNum, IsNumeric } from '../../../utils/scripts/decimal';
import { Button } from '../../../components';
import ReactCodeInput from 'react-verification-code-input';
import XPayLogo from 'assets/images/XPAY.png';
import XPayMask from 'assets/images/xpay-mask.png';
import { SITE_URL } from '../../../apis/constants';
import { toast } from 'react-toastify';
import { InfoCircleFilled } from '@ant-design/icons';

interface FieldError {
  phone: string[];
  password: string[];
}

interface HasError {
  phone: boolean;
  password: boolean;
}

enum Stage {
  ENTER_PHONE,
  ENTER_2FA,
}

export const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMeIsChecked, setRememberMeIsChecked] =
    useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [twoFactorType, setTwoFactorType] = useState<Enum>();
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [fieldError, setFieldError] = useState<FieldError>({
    password: [''],
    phone: [''],
  });
  const [hasError, setHasError] = useState<HasError>({
    phone: false,
    password: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [stage, setStage] = useState<Stage>(Stage.ENTER_PHONE);
  const [code, setCode] = useState<string>('');
  const [timer, setTimer] = useState<number>(120);

  const navigate = useNavigate();
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  function handleLoginSubmit(): void {
    setLoading(true);
    setHasError({
      phone: false,
      password: false,
    });
    setFieldError({
      phone: [''],
      password: [''],
    });
    if (phoneValue.search(phoneRegex) === 0 && passwordValue.length !== 0) {
      post(API.login, {
        phoneNumber: phoneValue,
        password: passwordValue,
      })
        .then(function (response: any) {
          setLoading(false);
          if (responseValidator(response.status)) {
            if (response.data.value.isTwoFactorEnabled) {
              localStorage.setItem(
                'twoFactorMethod',
                response.data.value.twoFactorMethod,
              );
              localStorage.setItem('phoneNumber', phoneValue);
              setTwoFactorType(response.data.value.twoFactorMethod);
              setTimer(120);
              setStage(Stage.ENTER_2FA);
            } else {
              toast.info('در حال انتقال به پنل');

              localStorage.setItem('token', response.data.value.access_token);
              response.data.value.fullName &&
                localStorage.setItem('name', response.data.value.fullName);
              localStorage.setItem(
                'twoFactorMethod',
                response.data.value.twoFactorMethod,
              );
              localStorage.setItem('auth', response.data.value.isAuthorized);
              localStorage.setItem('phone', phoneValue);
              localStorage.setItem('date', moment.utc().toString());
              localStorage.setItem('ref', response.data.value.refresh_token);
              localStorage.setItem(
                'expires_in',
                response.data.value.expires_in,
              );
              window.open('/dashboard', '_self');
            }
          }
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    } else {
      setLoading(false);
      if (passwordValue.length === 0) {
        setHasError((prev) => ({
          ...prev,
          password: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          password: ['لطفا پسورد را وارد کنید.'],
        }));
      }
      if (phoneValue.search(phoneRegex) !== 0) {
        setHasError((prev) => ({
          ...prev,
          phone: true,
        }));
        setFieldError((prev) => ({
          ...prev,
          phone: ['شماره موبایل وارد شده نادرست است.'],
        }));
      }
    }
  }

  function handleCodeSubmit(code: string): void {
    if (code.length !== 6) toast.error('کد وارد شده صحیح نمی باشد.');
    setLoading(true);
    post(API.verify_2fa, {
      phoneNumber: phoneValue,
      code: code,
    })
      .then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.info('در حال انتقال به پنل');

          localStorage.setItem('token', response.data.value.access_token);
          response.data.value.fullName &&
            localStorage.setItem('auth', response.data.value.isAuthorized);
          localStorage.setItem('phone', phoneValue || '');
          localStorage.setItem('name', response.data.value.fullName);
          localStorage.setItem('date', moment.utc().toString());
          localStorage.setItem('ref', response.data.value.refresh_token);
          localStorage.setItem('expires_in', response.data.value.expires_in);
          window.open('/dashboard', '_self');
        }
      })
      .catch(function (error: any) {
        toast.error(error);
        setLoading(false);
      });
  }

  function handlePhoneChange(value: string): void {
    setHasError((prev) => ({
      ...prev,
      phone: false,
    }));

    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    setPhoneValue(value);
  }

  function handlePasswordChange(value: string): void {
    if (value.length > 0) {
      setFieldError((prev) => ({
        ...prev,
        password: [''],
      }));
      setHasError((prev) => ({
        ...prev,
        password: false,
      }));
    }
    setPasswordValue(value);
  }

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('auth');
    localStorage.removeItem('ref');
    localStorage.removeItem('date');
    localStorage.removeItem('phone');
    localStorage.removeItem('name');
    localStorage.removeItem('profile');
    localStorage.removeItem('twoFactorMethod');
  }, []);

  return (
    <div className={'xpay-login'}>
      <div className={'login-content'}>
        <div className={'xpay-heading'}>
          <img
            src={XPayLogo}
            alt={'XPayLogo'}
            onClick={() => navigate(SITE_URL)}
          />
          <a href={SITE_URL}>
            <span>بازگشت به صفحه ی اصلی</span>
            <BackIcon />
          </a>
        </div>
        <img
          src={XPayLogo}
          alt={'XPayLogo'}
          onClick={() => navigate(SITE_URL)}
          className={'xpay-logo-desktop'}
        />
        <div className={'login-form'}>
          <div className={'domain-assurance'}>
            <InfoCircleFilled />
            <p>
              مطمئن شوید که در دامنه
              <a href={'https://xpay.co'} className={'en'}>
                https://xpay.co
              </a>{' '}
              هستید.
            </p>
          </div>
          {stage === Stage.ENTER_PHONE && (
            <>
              <h2>ورود به حساب کاربری</h2>
              <p>
                جهت ورود، شماره همراهی که ثبت نام را با آن انجام داده و رمز عبور
                خود را وارد نمایید.
              </p>
              <Input
                name="phoneNumber"
                type="text"
                className="phoneNumber-input"
                onPressEnter={() => handleLoginSubmit()}
                value={phoneValue}
                onChange={(el) => handlePhoneChange(el.target.value)}
                placeholder="موبایل"
                prefix={<CallIcon />}
                maxLength={11}
                dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
              />
              <Input.Password
                name="password"
                type="text"
                className="password-input"
                onPressEnter={() => handleLoginSubmit()}
                value={passwordValue}
                onChange={(el) => handlePasswordChange(el.target.value)}
                placeholder="رمز عبور"
                prefix={<LockIcon />}
                dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
              />

              <div className={'row'}>
                <div className={'checkbox'}>
                  <input
                    type="checkbox"
                    checked={rememberMeIsChecked}
                    onChange={(e) =>
                      setRememberMeIsChecked(!rememberMeIsChecked)
                    }
                  />

                  <span>من را به خاطر داشته باش</span>
                </div>
                <a href={'/password-recovery'}>دسترسی ندارید؟</a>
              </div>

              <Button
                type={'primary'}
                className={'login-button'}
                text={'ورود به حساب کاربری'}
                onClick={handleLoginSubmit}
                loading={loading}
                disabled={loading || phoneValue.length < 11 || !passwordValue}
              />

              <div className={'row'}>
                <div className={'no-account'}>
                  <span>حساب کاربری ندارید؟</span>
                  <a href={'/register'}>ثبت نام کنید</a>
                </div>
                <a href={SITE_URL}>بازگشت به سایت</a>
              </div>
            </>
          )}
          {stage === Stage.ENTER_2FA && (
            <>
              <h2>تایید شماره همراه</h2>
              <p>
                {localStorage.getItem('twoFactorMethod') === '2'
                  ? 'کد ۶ رقمی Google Authenticator خود را وارد کنید'
                  : `کد ۶ رقمی پیامک شده شده به شماره ی ${phoneValue} را وارد کنید`}
              </p>
              <ReactCodeInput
                className={`input`}
                fieldWidth={48}
                fieldHeight={48}
                values={code ? code.split('') : []}
                autoFocus={true}
                onChange={(value) => {
                  setCode(value);
                }}
                onComplete={(e) => handleCodeSubmit(e)}
                fields={6}
              />
              {localStorage.getItem('twoFactorMethod') !== '2' && (
                <div className={'row'}>
                  {timer > 0 ? (
                    <p id="waiting">{timer} ثانیه تا ارسال مجدد کد</p>
                  ) : (
                    <p id="resend" onClick={() => handleCodeSubmit(code)}>
                      ارسال مجدد کد
                    </p>
                  )}

                  <a onClick={() => setStage(Stage.ENTER_PHONE)}>
                    تغییر شماره همراه
                  </a>
                </div>
              )}
              <div className={'buttons'}>
                <Button
                  type={'primary'}
                  text={'تایید کد'}
                  className={'code-submit-button'}
                  loading={loading}
                  disabled={loading || code.length !== 6}
                />
                <Button
                  type={'outlined'}
                  text={'بازگشت'}
                  icon={<BackIcon />}
                  className={'back-button'}
                  onClick={() => {
                    setStage(Stage.ENTER_PHONE);
                    setCode('');
                  }}
                />
              </div>
            </>
          )}
        </div>
        <img src={XPayMask} alt={'XPayMask'} className={'xpay-mask'} />
      </div>
      <div className={'login-banner'}>
        <a href={SITE_URL}>
          <span>بازگشت به صفحه ی اصلی</span>
          <BackIcon />
        </a>
      </div>
    </div>
  );
};
