import './profile.scss';
import { ReactComponent as BlueRectangle } from 'assets/icons/profile/blue-rectangle.svg';
import { ReactComponent as GreyRectangle } from 'assets/icons/profile/grey-rectangle.svg';
import { Switch } from 'antd';
import Progress from 'antd/es/progress';
import { API } from 'apis';
import { Button, Card } from 'components';
import { Modal } from 'components/modal/modal';
import { ProfileType } from 'core/domains/profile/profile';
import { getMineProfileOtp } from 'core/repositories/profile/profile';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { ChangeEmailAddress } from './change-email';
import { ChangePassword } from './change-password/change-password';
import { TwoStepVerify } from './two-step-verify/two-step-verify';
import { NotificationsPage } from '../notifications/notifications';
import { Spinner } from '../../../components/spinner/spinner';
import { toast } from 'react-toastify';
import { GoogleAuthentication } from './google-authentication/google-authentication';
// import { GoogleAuthentication } from './google-authentication/google-authentication';

const slides = [
  {
    id: 1,
    title:
      'برای مشاهده دسترسی ها و محدودیت های هر سطح وارد پیج احراز هویت شوید',
    description: '',
    image: '/assets/images/slides/income-glass.svg',
    children: (
      <Link to={'/dashboard/authentication'}>
        <Button type="secondary" text="ورود به صفحه" />
      </Link>
    ),
  },
];

const { useState, useEffect } = React;

export const Profile: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<
    'otp' | 'password' | 'email' | 'google'
  >('otp');
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [twoFaStage, setTwoFaStage] = useState<
    'PHONE' | 'DOWNLOAD' | 'QRCODE' | 'CODE' | 'RECOVERY'
  >('PHONE');

  const [securityCardLoading, setSecurityCardLoading] =
    useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileType>();

  const [otpType, setOtpType] = useState<'sms' | 'google' | 'none'>('none');

  const [loading, setLoading] = useState<boolean>(false);
  const [switchLoading, setSwitchLoading] = useState<boolean>(false);

  const [selectedOtp, setSelectedOtp] = useState<'sms' | 'google'>();

  const [isGoogleAuthModalOpen, setIsGoogleAuthModalOpen] =
    useState<boolean>(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] =
    useState<boolean>(false);

  const handleOtpTypeChange = (type: 'sms' | 'google', checked: boolean) => {
    setSwitchLoading(true);
    setSelectedOtp(type);
    if (type === 'sms') {
      postViaAuth(API.profile.sendOtp, {}).then((response: any) => {
        setSecurityCardLoading(false);
        setSwitchLoading(false);
        if (responseValidator(response.status)) {
          setModalStage('otp');
          setModalIsOpen(true);
          toast.success('کد تایید ارسال شد');
        }
      });
    } else {
      if (checked) {
        setTwoFaStage('DOWNLOAD');
        setModalIsOpen(true);
        setSecurityCardLoading(false);
        setSwitchLoading(false);
      } else {
        postViaAuth(API.profile.sendOtp, {}).then((response: any) => {
          setSecurityCardLoading(false);
          setSwitchLoading(false);

          if (responseValidator(response.status)) {
            setTwoFaStage('CODE');
            setModalIsOpen(true);
          }
        });
      }
    }
  };
  useEffect(() => {
    if (profileData) {
      if (otpType === 'sms') {
        setProfileData({
          ...profileData,
          twoFactor: { isActive: true, type: { id: 1, title: 'اس ام اس' } },
        });
      } else if (otpType === 'google') {
        setProfileData({
          ...profileData,
          twoFactor: { isActive: true, type: { id: 2, title: 'گوگل' } },
        });
      }
    }
  }, [otpType]);

  const changeEmailModalHandle = () => {
    setModalStage('email');
    setModalIsOpen(true);
  };

  const changePasswordModalHandle = () => {
    setModalIsOpen(true);
    setModalStage('password');
  };

  useEffect(() => {
    getMineProfileOtp(setLoading, setProfileData, setOtpType);
  }, []);

  return (
    <>
      <GoogleAuthentication
        isOpen={isGoogleAuthModalOpen}
        setIsOpen={setIsGoogleAuthModalOpen}
      />
      <Modal
        title={`${
          modalStage === 'otp'
            ? 'تایید صحت دو مرحله‌ای'
            : modalStage === 'password'
              ? 'رمز عبور جدید'
              : modalStage === 'google'
                ? 'Google Authenticator'
                : 'ویرایش آدرس ایمیل'
        }`}
        closeAble
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        onClose={() => {}}
      >
        {profileData && modalStage === 'otp' ? (
          <TwoStepVerify
            setTwoFaStage={setTwoFaStage}
            twoFaStage={twoFaStage}
            selectedOtp={selectedOtp}
            state={profileData.twoFactor}
            phone={profileData.phoneNumber}
            setIsOpen={setModalIsOpen}
            getdata={() =>
              getMineProfileOtp(setLoading, setProfileData, setOtpType)
            }
          />
        ) : modalStage === 'password' ? (
          <ChangePassword
            phone={profileData?.phoneNumber}
            setIsOpen={setModalIsOpen}
          />
        ) : (
          <ChangeEmailAddress
            phone={profileData?.phoneNumber}
            setIsOpen={setModalIsOpen}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        )}
      </Modal>

      <Spinner loading={loading}>
        <div className="xpay-dashboard-profile">
          <Card
            className="profile-card"
            title="سطح کاربری"
            href="/dashboard/authentication"
            hrefTitle="ارتقاء سطح"
            divider
          >
            <div className="right">
              <div className="stepper">
                <div
                  className={`step ${profileData?.level.uniqueName.id === 3 || profileData?.level.uniqueName.id === 2 || profileData?.level.uniqueName.id === 1 ? 'active' : ''}`}
                >
                  <div className="circle">1</div>
                  <div className="text">سطح پایه</div>
                </div>
                <div
                  className={`line ${profileData?.level.uniqueName.id === 3 || profileData?.level.uniqueName.id === 2 ? 'active' : ''}`}
                ></div>
                <div
                  className={`step ${profileData?.level.uniqueName.id === 3 || profileData?.level.uniqueName.id === 2 ? 'active' : ''}`}
                >
                  <div className="circle">2</div>
                  <div className="text">سطح یک</div>
                </div>
                <div
                  className={`line ${profileData?.level.uniqueName.id === 3 ? 'active' : ''}`}
                ></div>
                <div
                  className={`step ${profileData?.level.uniqueName.id === 3 ? 'active' : ''}`}
                >
                  <div className="circle">3</div>
                  <div className="text">سطح دو</div>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="col">
                <div>
                  <BlueRectangle />
                  <span>احراز هویت شده</span>
                </div>
                <div>
                  <GreyRectangle />
                  <span>در انتظار تکمیل اطلاعات</span>
                </div>
              </div>
              {profileData && (
                <Progress
                  success={{
                    strokeColor: '#1552F0',
                  }}
                  type="circle"
                  percent={
                    profileData &&
                    (profileData.level?.uniqueName?.id - 1) * (100 / 2)
                  }
                  strokeWidth={10}
                  strokeColor={{
                    '-25.7%': `${
                      profileData.level?.uniqueName.id - 1 == 2
                        ? '#678AF5'
                        : '#4f46e0'
                    }`,
                    '112.1%': `${
                      profileData.level.uniqueName.id - 1 == 2
                        ? '#4f46e0'
                        : '#678AF5'
                    }`,
                  }}
                  format={(precent) => (
                    <div className="percent-info">
                      <span>{precent}٪</span>
                      <span>
                        {'سطح ' +
                          `${
                            profileData.level?.uniqueName?.id - 1 == 2
                              ? 'دو'
                              : profileData.level?.uniqueName?.id - 1 == 1
                                ? 'یک'
                                : profileData.level?.uniqueName?.id - 1 == 0
                                  ? 'پایه'
                                  : ''
                          }`}
                      </span>
                    </div>
                  )}
                />
              )}
            </div>
          </Card>
          <Card
            blur={securityCardLoading}
            className="security-card desktop-show"
          >
            <div className="security">
              <h4>امنیت</h4>
              <div className="content">
                <p>رمز عبور</p>
                <div className="password">
                  <Button
                    type="outlined"
                    text="تغییر رمز عبور"
                    onClick={() => changePasswordModalHandle()}
                    disabled={modalLoading}
                    loading={modalLoading}
                  />
                </div>
              </div>
            </div>
            <div className="two-step">
              <h4>تایید دو مرحله‌ای</h4>
              <div className="content">
                <div className="static">
                  <div>
                    <p>پیامک</p>
                    <span>دریافت تأیید صحت دو مرحله‌ای از طریق پیامک</span>
                  </div>
                  <div>
                    <p>اپلیکیشن</p>
                    <span>
                      دریافت کد از طریق اپلیکیشن‌های مانند Google Authenticator
                    </span>
                  </div>
                </div>
                <div className="dynamic">
                  <Switch
                    loading={switchLoading}
                    disabled={switchLoading}
                    checked={otpType === 'sms' ? true : false}
                    onChange={(e) => handleOtpTypeChange('sms', e)}
                  />
                  {/*<Switch*/}
                  {/*  loading={switchLoading}*/}
                  {/*  disabled={switchLoading}*/}
                  {/*  checked={otpType === 'google' ? true : false}*/}
                  {/*  onChange={(e) => handleOtpTypeChange('google', e)}*/}
                  {/*/>*/}
                  <Button
                    text="فعال سازی"
                    type="outlined"
                    onClick={() => setIsGoogleAuthModalOpen(true)}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card
            blur={securityCardLoading}
            className="security-card mobile-show"
          >
            <div className="security">
              <h4>امنیت</h4>
              <div className="content">
                <p>رمز عبور</p>
                <div className="password">
                  <Button
                    type="outlined"
                    text="تغییر رمز عبور"
                    onClick={() => changePasswordModalHandle()}
                    disabled={modalLoading}
                    loading={modalLoading}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card
            blur={securityCardLoading}
            className="security-card mobile-show"
          >
            <div className="two-step">
              <h4>تایید دو مرحله‌ای</h4>
              <div className="content">
                <div className="static">
                  <div>
                    <p>پیامک</p>
                    <span>دریافت تأیید صحت دو مرحله‌ای از طریق پیامک</span>
                  </div>
                  <div>
                    <p>اپلیکیشن</p>
                    <span>
                      دریافت کد از طریق اپلیکیشن‌های مانند Google Authenticator
                    </span>
                  </div>
                </div>
                <div className="dynamic">
                  <Switch
                    loading={switchLoading}
                    disabled={switchLoading}
                    checked={otpType === 'sms' ? true : false}
                    onChange={(e) => handleOtpTypeChange('sms', e)}
                  />
                  {/* <Switch
                    loading={switchLoading}
                    disabled={switchLoading}
                    checked={otpType === 'google' ? true : false}
                    onChange={(e) => handleOtpTypeChange('google', e)}
                  /> */}
                  <Button
                    text="فعال سازی"
                    type="outlined"
                    onClick={() => setIsGoogleAuthModalOpen(true)}
                  />
                </div>
              </div>
            </div>
          </Card>
          <NotificationsPage />
          {/* <div className="info-row">
            <Card className="user-info">
              {profileData && (
                <>
                  <div className="personal">
                    <h4>اطلاعات فردی</h4>
                    <div className="content">
                      <div className="static">
                        <p>نام و نام خانوادگی</p>
                        <p>کد ملی</p>
                        <p>تاریخ تولد</p>
                      </div>
                      <div className="dynamic">
                        <p>{profileData?.fullName}</p>
                        <p>{profileData?.nationalCode}</p>
                        <p>{profileData?.dateOfBirth.split(' ')[0]}</p>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="contact">
                    <h4>اطلاعات تماس</h4>
                    <div className="content">
                      <div className="static">
                        <p>شماره تماس</p>
                        <p>ایمیل(اختیاری)</p>
                      </div>
                      <div className="dynamic">
                        <p>{profileData?.phoneNumber ?? '-'}</p>

                        <div className="content">
                          <p className="email">
                            {profileData?.emailAddress ?? '-'}
                          </p>
                          <Tooltip title="ویرایش آدرس ایمیل">
                            <EditOutlined
                              onClick={() => changeEmailModalHandle()}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
            <Card className="user-level">
              {profileData && (
                <>
                  <div className="level">
                    <div>
                      <h4>سطح کاربری</h4>
                      <div className="status">
                        <span></span>
                        <p>احراز هویت شده</p>
                      </div>
                      <Link to={'/dashboard/authentication'}>
                        <Button text="ارتقاء سطح" type="info" />
                      </Link>
                    </div>
                    <div>

                    </div>
                  </div>
                  <Divider />
                  <div className="slider">
                    <div className="xpay-slider">
                      <Swiper
                        modules={[Navigation, Autoplay]}
                        navigation
                        loop
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay
                      >
                        {slides.map((slide, index) => {
                          return (
                            <SwiperSlide>
                              <div className="slide-overlay"></div>
                              <div className="xpay-slide">
                                <div className="right-side">
                                  <h2>{slide.title}</h2>
                                  <p>
                                    {slide.description && slide.description}
                                  </p>
                                  {slide.children}
                                </div>
                                <img
                                  className={`shape ${index === 1 ? 'machine' : ''
                                    }`}
                                  src={slide.image}
                                  alt=""
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div> */}

          {/* <Card className="sessions-row">
          <h4>دستگاه های متصل</h4>

          <div className="desktop-show">
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              style={{ borderRadius: '10px' }}
              pagination={false}
            />
          </div>
          <ul className="mobile-show">
            {dataSource.map((device) => (
              <li>
                <div className="row">
                  <div className="right">
                    <span>{device.model}</span>
                  </div>
                  <div className="left">
                    <p>خروج</p>
                    <DeleteOutlined />
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>آخرین زمان اتصال: </p>
                    <span>{device.lastTouch}</span>
                  </div>
                  <div className="left">
                    <span>{device.ip}</span>
                    <p>:IP</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Card> */}
        </div>
      </Spinner>
    </>
  );
};
