import './password-recovery.scss';
import { passwordRegex, phoneRegex } from '../../../utils/constants/constants';
import {
  post,
  postViaAuth,
  responseValidator,
} from '../../../utils/scripts/api';
import { API } from '../../../apis';
import { useEffect, useState } from 'react';
import { Enum } from '../../../core/domains';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/icons/main/Back.svg';
import { ReactComponent as CallIcon } from 'assets/icons/main/Call.svg';
import { ReactComponent as LockIcon } from 'assets/icons/main/Lock.svg';
import { Input } from 'antd';
import * as React from 'react';
import { faNumToEnNum, IsNumeric } from '../../../utils/scripts/decimal';
import { Button } from '../../../components';
import ReactCodeInput from 'react-verification-code-input';
import XPayLogo from 'assets/images/XPAY.png';
import XPayMask from 'assets/images/xpay-mask.png';
import { SITE_URL } from '../../../apis/constants';
import { toast } from 'react-toastify';
import { InfoCircleFilled } from '@ant-design/icons';

interface FieldError {
  phone: string[];
  password: string[];
}

interface HasError {
  phone: boolean;
  password: boolean;
}

enum Stage {
  ENTER_PHONE,
  ENTER_CODE,
  ENTER_PASSWORD,
}

export const PasswordRecovery = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMeIsChecked, setRememberMeIsChecked] =
    useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [twoFactorType, setTwoFactorType] = useState<Enum>();
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [fieldError, setFieldError] = useState<FieldError>({
    password: [''],
    phone: [''],
  });
  const [hasError, setHasError] = useState<HasError>({
    phone: false,
    password: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [stage, setStage] = useState<Stage>(Stage.ENTER_PHONE);
  const [code, setCode] = useState<string>('');
  const [timer, setTimer] = useState<number>(120);

  const navigate = useNavigate();
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  function handlePhoneNumberSubmit(): void {
    setLoading(true);
    if (phoneValue.search(phoneRegex) !== 0) {
      setLoading(false);
    } else {
      post(API.forgotPassword, { phoneNumber: phoneValue }).then(function (
        response: any,
      ) {
        setLoading(false);
        if (responseValidator(response.status)) {
          localStorage.setItem('phoneNumber', phoneValue);
          setStage(Stage.ENTER_CODE);
          setTimer(120);
        } else {
          toast.error(response.data.message);
        }
      });
    }
  }

  function handleVerify(codeValue: string) {
    setLoading(true);
    if (codeValue.length === 6) {
      post(API.verifyForgotPassword, {
        code: codeValue,
        phoneNumber: phoneValue,
      }).then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          localStorage.setItem('token', response.data.value.access_token);
          setStage(Stage.ENTER_PASSWORD);
        } else {
          toast.error(response.data.message);
        }
      });
    } else {
      setLoading(false);
    }
  }

  function handleResend() {
    setCode('');
    post(API.forgotPassword, {
      phoneValue,
    }).then(function (response: any) {
      if (responseValidator(response.status)) {
        toast.success('کد تایید ارسال شد.');
        setTimer(120);
      } else {
        toast.error(response.data.message);
      }
    });
  }

  function handlePhoneChange(value: string): void {
    setHasError((prev) => ({
      ...prev,
      phone: false,
    }));

    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    setPhoneValue(value);
  }

  function handlePasswordChange(value: string): void {
    if (value.length > 0) {
      setFieldError((prev) => ({
        ...prev,
        password: [''],
      }));
      setHasError((prev) => ({
        ...prev,
        password: false,
      }));
    }
    setPasswordValue(value);
  }

  function handleRepeatPasswordChange(value: string): void {
    if (value.length > 0) {
      setFieldError((prev) => ({
        ...prev,
        password: [''],
      }));
      setHasError((prev) => ({
        ...prev,
        password: false,
      }));
    }
    setRepeatPasswordValue(value);
  }

  async function handlePasswordSubmit() {
    setLoading(true);
    if (
      passwordValue.search(passwordRegex) === 0 &&
      passwordValue.length !== 0 &&
      repeatPasswordValue === passwordValue
    ) {
      await postViaAuth(API.setPassword, {
        password: passwordValue,
        repeatPassword: repeatPasswordValue,
      }).then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('پسورد شما با موفقیت بازیابی شد.');
          navigate('/login/');
        } else {
          toast.error(response.data.message);
        }
      });
    } else {
      setLoading(false);
      if (passwordValue !== repeatPasswordValue) {
      }
      if (
        passwordValue.search(passwordRegex) !== 0 ||
        passwordValue.length === 0
      ) {
      }
    }
  }

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('auth');
    localStorage.removeItem('ref');
    localStorage.removeItem('date');
    localStorage.removeItem('phone');
    localStorage.removeItem('name');
    localStorage.removeItem('profile');
    localStorage.removeItem('twoFactorMethod');
  }, []);

  return (
    <div className={'xpay-password-recovery'}>
      <div className={'password-recovery-content'}>
        <div className={'xpay-heading'}>
          <img src={XPayLogo} alt={'XPayLogo'} onClick={() => navigate('/')} />
          <a href={SITE_URL}>
            <span>بازگشت به صفحه ی اصلی</span>
            <BackIcon />
          </a>
        </div>
        <img
          src={XPayLogo}
          alt={'XPayLogo'}
          onClick={() => navigate(SITE_URL)}
          className={'xpay-logo-desktop'}
        />
        <div className={'password-recovery-form'}>
          <div className={'domain-assurance'}>
            <InfoCircleFilled />
            <p>
              مطمئن شوید که در دامنه
              <a href={'https://xpay.co'} className={'en'}>
                https://xpay.co
              </a>{' '}
              هستید.
            </p>
          </div>

          {stage === Stage.ENTER_PHONE && (
            <>
              <h2>بازیابی رمز عبور</h2>
              <p>
                برای بازیابی رمز عبور، شماره موبایلی که با آن ثبت نام را انجام
                داده اید وارد نمایید.
              </p>
              <Input
                name="phoneNumber"
                type="text"
                className="phoneNumber-input"
                onPressEnter={handlePhoneNumberSubmit}
                value={phoneValue}
                onChange={(el) => handlePhoneChange(el.target.value)}
                placeholder="موبایل"
                prefix={<CallIcon />}
                maxLength={11}
                dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
              />

              <Button
                type={'primary'}
                className={'password-recovery-button'}
                text={'ارسال کد بازیابی'}
                onClick={handlePhoneNumberSubmit}
                loading={loading}
                disabled={loading || phoneValue.length < 11}
              />

              <div className={'row'}>
                <div className={'has-account'}>
                  <span>حساب کاربری ندارید؟</span>
                  <a href={'/register'}> ثبت نام کنید</a>
                </div>
                <a href={SITE_URL}>بازگشت به سایت</a>
              </div>
            </>
          )}
          {stage === Stage.ENTER_CODE && (
            <>
              <h2>تایید شماره همراه</h2>
              <p>
                {localStorage.getItem('twoFactorMethod') === '2'
                  ? 'کد ۶ رقمی Google Authenticator خود را وارد کنید'
                  : `کد ۶ رقمی پیامک شده شده به شماره ی ${phoneValue} را وارد کنید`}
              </p>
              <ReactCodeInput
                className={`input`}
                fieldWidth={48}
                fieldHeight={48}
                values={code ? code.split('') : []}
                autoFocus={true}
                onChange={(value) => {
                  setCode(value);
                }}
                onComplete={(e) => handleVerify(e)}
                fields={6}
              />
              {localStorage.getItem('twoFactorMethod') !== '2' && (
                <div className={'row'}>
                  {timer > 0 ? (
                    <p id="waiting">{timer} ثانیه تا ارسال مجدد کد</p>
                  ) : (
                    <p id="resend" onClick={() => handleResend}>
                      ارسال مجدد کد
                    </p>
                  )}

                  <a onClick={() => setStage(Stage.ENTER_PHONE)}>
                    تغییر شماره همراه
                  </a>
                </div>
              )}
              <div className={'buttons'}>
                <Button
                  type={'primary'}
                  text={'تایید کد'}
                  className={'code-submit-button'}
                  onClick={() => handleVerify(code)}
                />
                <Button
                  type={'outlined'}
                  text={'بازگشت'}
                  icon={<BackIcon />}
                  className={'back-button'}
                  onClick={() => setStage(Stage.ENTER_PHONE)}
                />
              </div>
            </>
          )}
          {stage === Stage.ENTER_PASSWORD && (
            <>
              <h2>تنظیم رمز عبور جدید</h2>
              <p>رمز عبور جدید خود را با رعایت نکات ذکر شده تنظیم نمایید.</p>
              <Input.Password
                name="password"
                type="text"
                className="password-input"
                value={passwordValue}
                onChange={(el) => handlePasswordChange(el.target.value)}
                placeholder="رمز عبور"
                prefix={<LockIcon />}
                dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
              />
              <Input.Password
                name="repeat-password"
                type="text"
                className="password-input"
                value={repeatPasswordValue}
                onChange={(el) => handleRepeatPasswordChange(el.target.value)}
                placeholder="تکرار رمز عبور"
                prefix={<LockIcon />}
                dir={repeatPasswordValue.length !== 0 ? 'ltr' : 'rtl'}
              />
              <Button
                type={'primary'}
                text={'تایید'}
                className={'password-recovery-button'}
                onClick={handlePasswordSubmit}
              />
            </>
          )}
        </div>
        <img src={XPayMask} alt={'XPayMask'} className={'xpay-mask'} />
      </div>
      <div className={'password-recovery-banner'}>
        <a href={SITE_URL}>
          <span>بازگشت به صفحه ی اصلی</span>
          <BackIcon />
        </a>
      </div>
    </div>
  );
};
