import './place-order-details-modal.scss';
import { Alert, Button, Card, Modal } from '../../../../../components';
import { Dispatch, SetStateAction, useState } from 'react';
import close from '../../../../../assets/icons/close.png';
import * as React from 'react';
import { getIconPath } from '../../../../../utils/scripts/getIconPath';
import { Side } from '../create-order';
import { Market } from '../../../../../core/domains';
import { digitSeparator } from '../../../../../utils/scripts/exchange';
import { ReactComponent as BackIcon } from 'assets/icons/create-order/back.svg';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';
import { toast } from 'react-toastify';
import { CopyOutlined } from '@ant-design/icons';
import {
  copyClipboard,
  copyToClipboard,
} from '../../../../../utils/scripts/copyToClipboard';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  side: Side;
  sourceAmount: string;
  destinationAmount: string;
  baseSymbol: Market;
  quoteSymbol: string;
  commission: number;
  onComplete: () => void;
}

enum Stage {
  PREVIEW,
  RESULT,
}

interface OrderResult {
  orderId: string;
  trackingCode: string;
  sourceAmount: number;
  destinationAmount: number;
  commission: number;
  onPrice: number;
}

export const PlaceOrderDetailsModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  side,
  sourceAmount,
  destinationAmount,
  baseSymbol,
  commission,
  onComplete,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.PREVIEW);
  const [result, setResult] = useState<OrderResult>();

  const createOrder = () => {
    setLoading(true);
    postViaAuth(API.orders.create, {
      baseSymbol: baseSymbol.symbol,
      quoteSymbol: 'IRT',
      side: side === Side.Buy ? 1 : 2,
      sourceAmount: Number.parseFloat(
        sourceAmount.toString().replaceAll(',', ''),
      ),
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setResult(response.value);
        setStage(Stage.RESULT);
        toast.success('سفارش با موفقیت ثبت شد.');
        onComplete && onComplete();
      }
    });
  };

  return (
    <Modal
      className={'order-details-modal'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => setStage(Stage.PREVIEW)}
    >
      <Card
        title={'جزئیات تراکنش'}
        button={
          <img
            style={{ cursor: 'pointer' }}
            src={close}
            alt={'Close'}
            onClick={() => setIsOpen(false)}
          />
        }
        divider
      >
        {stage === Stage.PREVIEW && (
          <>
            <ul className={'order-details'}>
              <li>
                <span>نام ارز</span>
                <span>
                  <img
                    width={20}
                    height={20}
                    src={getIconPath(baseSymbol?.symbol?.toLowerCase())}
                  />
                  <span>{baseSymbol?.name}</span>
                </span>
              </li>
              <li>
                <span>نوع سفارش</span>
                <span>{side === Side.Buy ? 'خرید' : 'فروش'}</span>
              </li>
              <li>
                <span>کارمزد</span>
                <span>{digitSeparator(commission)} تومان</span>
              </li>
              <li>
                <span>مقدار {side === Side.Buy ? 'پرداختی' : 'فروشی'}</span>
                <span>
                  {side === Side.Buy
                    ? `${digitSeparator(sourceAmount)} تومان`
                    : `${digitSeparator(Number(sourceAmount).toFixed(6))} ${baseSymbol.symbol}`}
                </span>
              </li>
              <li>
                <span>مقدار دریافتی تقریبی</span>
                <span>
                  {side === Side.Buy
                    ? `${digitSeparator(destinationAmount)} ${baseSymbol.symbol}`
                    : `${digitSeparator(destinationAmount)} تومان`}
                </span>
              </li>
            </ul>
            <Alert
              type={'danger'}
              message={
                'مقدار تبدیلی که نمایش داده شده با مقداری که دریافت میکنید یکی نیست و نرخ نهایی در لحظه نهایی شدن تراکنش تعیین میگردد'
              }
            />
            <div className={'buttons'}>
              <Button
                type={'primary'}
                text={'ثبت سفارش'}
                onClick={createOrder}
                loading={loading}
              />
              <Button
                type={'outlined'}
                icon={<BackIcon />}
                text={'انصراف'}
                onClick={() => setIsOpen(false)}
              />
            </div>
          </>
        )}
        {stage === Stage.RESULT && (
          <>
            <ul className={'order-details'}>
              <li>
                <span>نام ارز</span>
                <span>
                  <img
                    width={20}
                    height={20}
                    src={getIconPath(baseSymbol?.symbol?.toLowerCase())}
                  />
                  <span>{baseSymbol?.name}</span>
                </span>
              </li>
              <li>
                <span>نوع سفارش</span>
                <span>{side === Side.Buy ? 'خرید' : 'فروش'}</span>
              </li>
              <li>
                <span>کارمزد</span>
                <span>{digitSeparator(result?.commission)} تومان</span>
              </li>
              <li>
                <span>مقدار {side === Side.Buy ? 'پرداختی' : 'فروشی'}</span>
                <span>
                  {side === Side.Buy
                    ? `${digitSeparator(result?.sourceAmount)} تومان`
                    : `${digitSeparator(Number(result?.sourceAmount).toFixed(6))} ${baseSymbol.symbol}`}
                </span>
              </li>
              <li>
                <span>مقدار دریافتی تقریبی</span>
                <span>
                  {side === Side.Buy
                    ? `${digitSeparator(result?.destinationAmount)} ${baseSymbol.symbol}`
                    : `${digitSeparator(result?.destinationAmount)} تومان`}
                </span>
              </li>
              <li>
                <span>قیمت در لحظه</span>
                <span>{result?.onPrice}</span>
              </li>
              <li>
                <span>کد پیگیری</span>
                <span>
                  {result?.trackingCode}&nbsp;
                  <CopyOutlined
                    onClick={() =>
                      copyClipboard(result?.trackingCode!, 'کد پیگیری')
                    }
                  />
                </span>
              </li>
            </ul>
            <div className={'buttons'}>
              <Button
                type={'primary'}
                text={'متوجه شدم'}
                onClick={() => {
                  setIsOpen(false);
                  setStage(Stage.PREVIEW);
                }}
                loading={loading}
              />
            </div>
          </>
        )}
      </Card>
    </Modal>
  );
};
