import './search-box.scss';
import { ReactComponent as Close } from 'assets/icons/create-order/Close.svg';
import { ReactComponent as Star } from 'assets/icons/create-order/Star.svg';
import { useState } from 'react';
import { getIconPath } from 'utils/scripts/getIconPath';
import { Divider } from 'antd';
import { Fiat, Market } from '../../../../core/domains';
import { OrderCommonProps, Side } from '../create/create-order';
import { AssetItem } from '../../../../core/domains/markets/asset';
import { digitSperator } from '../../../../utils/scripts/exchange';
import { Spinner } from '../../../../components/spinner/spinner';

interface SearchBoxProps extends OrderCommonProps {
  onCurrencySelect?: () => void;
}

export const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  useState<boolean>(false);

  return (
    <div className="xpay-search-box">
      <div className="search-bar">
        <input
          placeholder="جست و جوی رمز ارز..."
          value={searchInput}
          onChange={(event) => {
            setSearchInput(event.target.value);
          }}
        />

        <Close onClick={() => setSearchInput('')} />
      </div>
      <div className="favorite-currencies">
        <h4>
          <Star />
          رمز ارز های پرطرفدار
        </h4>
        <Spinner loading={props.fiatsLoading}>
          <div className="currencies-box">
            {props.fiats.length !== 0 &&
              props.fiats
                ?.filter((fiat) => fiat.symbol.toUpperCase() !== 'CRYPTO')
                .map((fiat: Fiat, index) => (
                  <div
                    key={fiat.id || index}
                    className="currency-item"
                    onClick={() => {
                      if (props.side === Side.Buy) {
                        const destinationMarket = props.markets?.find(
                          (allMarket) =>
                            allMarket.symbol.toUpperCase() ===
                            fiat.symbol.toUpperCase(),
                        );
                        if (destinationMarket)
                          props.setDestinationMarket(destinationMarket);
                      } else if (props.side === Side.Sell) {
                        const sourceMarket = props.markets?.find(
                          (allMarket) =>
                            allMarket.symbol.toUpperCase() ===
                            fiat.symbol.toUpperCase(),
                        );
                        if (sourceMarket) props.setSourceMarket(sourceMarket);
                      }
                      props.onCurrencySelect && props.onCurrencySelect();
                    }}
                  >
                    <img
                      alt="asset"
                      width={32}
                      height={32}
                      src={getIconPath(fiat?.symbol)}
                    />
                    <div className="col">
                      <p>{fiat?.name}</p>
                      <span className="en">{fiat?.symbol}</span>
                    </div>
                  </div>
                ))}
          </div>
        </Spinner>
      </div>

      <h4 id="list">لیست رمز ارز ها</h4>
      {props.side === Side.Buy && (
        <Spinner loading={props.marketsLoading}>
          <div className="currencies-list">
            {props.markets.length !== 0 &&
              props.markets
                .filter(
                  (market) =>
                    market.symbol.toUpperCase() !== 'IRT' &&
                    (market.symbol
                      .toLowerCase()
                      .startsWith(searchInput.toLowerCase()) ||
                      market.name.startsWith(searchInput)),
                )
                .map((market: Market, index) => (
                  <>
                    <div
                      className="row"
                      key={market.id || index}
                      onClick={() => {
                        if (props.side === Side.Buy) {
                          const destinationMarket = props.markets?.find(
                            (allMarket) =>
                              allMarket.symbol.toUpperCase() ===
                              market.symbol.toUpperCase(),
                          );
                          if (destinationMarket)
                            props.setDestinationMarket(destinationMarket);
                        } else if (props.side === Side.Sell) {
                          const sourceMarket = props.markets?.find(
                            (allMarket) =>
                              allMarket.symbol.toUpperCase() ===
                              market.symbol.toUpperCase(),
                          );
                          if (sourceMarket) props.setSourceMarket(sourceMarket);
                        }
                        props.onCurrencySelect && props.onCurrencySelect();
                      }}
                    >
                      <div className="name-icon">
                        <img
                          width={32}
                          height={32}
                          src={getIconPath(market?.symbol)}
                        />
                        <p>{market?.name}</p>
                      </div>
                      <span className="symbol en">{market?.symbol}</span>
                    </div>
                    <Divider key={`divider-${index}`} />
                  </>
                ))}
          </div>
        </Spinner>
      )}

      {props.side === Side.Sell && (
        <Spinner loading={props.assetsLoading}>
          <div className="currencies-list">
            {props.assets?.assets.length !== 0 &&
              props.assets?.assets
                .filter(
                  (asset) =>
                    asset.symbol.toUpperCase() !== 'IRT' &&
                    (asset.symbol
                      .toLowerCase()
                      .startsWith(searchInput.toLowerCase()) ||
                      asset.name.startsWith(searchInput)),
                )
                .map((asset: AssetItem, index) => (
                  <>
                    <div
                      className="row"
                      key={asset.id || index}
                      onClick={() => {
                        if (props.side === Side.Buy) {
                          const destinationMarket = props.markets?.find(
                            (allMarket) =>
                              allMarket.symbol.toUpperCase() ===
                              asset.symbol.toUpperCase(),
                          );
                          if (destinationMarket)
                            props.setDestinationMarket(destinationMarket);
                        } else if (props.side === Side.Sell) {
                          const sourceMarket = props.markets?.find(
                            (allMarket) =>
                              allMarket.symbol.toUpperCase() ===
                              asset.symbol.toUpperCase(),
                          );
                          if (sourceMarket) props.setSourceMarket(sourceMarket);
                        }
                        props.onCurrencySelect && props.onCurrencySelect();
                      }}
                    >
                      <div className="name-icon">
                        <img
                          width={32}
                          height={32}
                          src={getIconPath(asset?.symbol)}
                        />
                        <p>{asset?.name}</p>
                      </div>
                      <span className="symbol">
                        {'موجودی: ' + digitSperator(asset.available) + ' واحد'}
                      </span>
                    </div>
                    <Divider key={`divider-${index}`} />
                  </>
                ))}
          </div>
        </Spinner>
      )}
    </div>
  );
};
