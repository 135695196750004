// orders-navigation-page.js

import './orders-navigation.scss';
import { useNavigate } from 'react-router-dom';
import { OrderNavigationItem } from './orders-navigation-item/orders-navigation-item';

export const OrdersNavigationPage = () => {
  const OrdersNavigationItems = [
    {
      id: 0,
      title: 'خرید و فروش',
      link: '/orders',
      orderType: 0,
    },
    {
      id: 1,
      title: 'واریز و برداشت تومانی',
      link: '/orders',
      orderType: 1,
    },
    {
      id: 2,
      title: 'واریز و برداشت ارزی',
      link: '/orders',
      orderType: 2,
    },
    {
      id: 3,
      title: 'خرید و فروش پرفکت مانی',
      link: '/orders',
      orderType: 3,
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (orderType: number) => {
    navigate(`/dashboard/orders?type=${orderType}`);
  };

  return (
    <div className="xpay-orders-navigation">
      {OrdersNavigationItems.map((item, index) => (
        <OrderNavigationItem
          key={item.id}
          id={item.id}
          title={item.title}
          onClick={() => handleNavigation(item.orderType)}
        />
      ))}
    </div>
  );
};
