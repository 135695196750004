import { Button, Card } from 'components'
import './authentication-one.scss'
import { ReactComponent as Close } from 'assets/icons/authentication/Close.svg'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { DatePicker } from 'components/date-picker/date-picker'
import { ReactComponent as Continue } from 'assets/icons/authentication/Continue.svg';
import { Divider } from 'antd'
import warning from 'assets/images/authentication/warning.png'
import { requestUpgradeToLevel1, verifyUpgradeToLevel1 } from 'core/repositories'
import { dateSlasher } from 'utils/scripts/dateSlasher'

interface LevelOneInfo {
    nationalCode: string;
    birthDate: string;
    email: string;
}

interface Identity {
    nationalCode: string,
    fullName: string,
    fatherName: string,
    birthDate: string,
    firstName: string,
    lastName: string,
    gender: string,
    email: string
}

export const AuthenticationOne = () => {
    const navigate = useNavigate();
    const parsedProfile = JSON.parse(localStorage.getItem('profile') ?? 'null');
    const [step, setStep] = useState<number>(-1)
    const [info, setInfo] = useState<LevelOneInfo>({ nationalCode: '', birthDate: '', email: '' });
    const [identity, setIdentity] = useState<Identity>()
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false)
    const [isMobileCheckboxChecked, setIsMobileCheckboxChecked] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = () => {
        requestUpgradeToLevel1(setLoading, info, setIdentity, () => setStep((step) => step + 1));
    }

    const handleVerify = () => {
        verifyUpgradeToLevel1(setLoading, () => {navigate('/dashboard/authentication')})
    }

    useEffect(() => {
        parsedProfile?.level?.uniqueName?.id !== 1 && navigate('/')
    }, [])

    return (
        <>
            <Card title='ارتقا به سطح یک' className='xpay-authentication-one' button={<Close onClick={() => navigate('/dashboard/authentication')} />} divider>
                {step == -1 &&
                    <div className="step-warning-content">
                        <div className="bg">
                            <img src={warning} />
                            <span>هشــدار!</span>
                        </div>
                        <p>ممکنه کاسه ای زیر نیم کاسه باشه!</p>
                        <p>اگر فردی از شما درخواست ثبت نام در سایت های ارز دیجیتال مانند ایکس پی را کرده و در قبال آن پیشنهاد دریافت حقوق و کارمزد را داده است، از پذیرفتن آن جدا خودداری کنید. چرا که این افراد قصد پولشویی از طریق حساب های بانکی شما دارند که در این صورت شما را درگیر مسائل پیچیده قضایی خواهد کرد. جهت دریافت اطلاعات بیشتر با پشتیبانی ایکس پی در تماس باشید.</p>
                        <span><input type='checkbox' checked={isCheckboxChecked} onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} /> متن و قوانین را مشاهده کردم و از موارد اشاره شده آگاه هستم</span>
                        <Button type='primary' text='تایید قوانین و ادامه' disabled={!isCheckboxChecked} onClick={() => setStep(0)} />
                    </div>
                }
                {step == 0 &&
                    <div className="step-agreement-content">
                        <p>تایید این مرحله به منزله ی پذیرش تمامی قوانین است، آیا تایید میکنید؟</p>
                        <div className='buttons'>
                            <Button text='تایید قوانین و ادامه' type='primary' onClick={() => setStep(1)} />
                            <Button text='رد و بازگشت' type='secondary' onClick={() => setStep(-1)} />
                        </div>
                    </div>
                }
                {step == 1 &&
                    <div className='step-1-content'>
                        <div className="field">
                            <input maxLength={10} placeholder='کد ملی' value={info?.nationalCode} onChange={(e) => setInfo({ ...info, nationalCode: e.target.value })} />
                        </div>
                        <div className="field">
                            <input placeholder='تاریخ تولد' value={info?.birthDate} />
                            <DatePicker className='date-picker' onChange={(value) =>
                                setInfo({
                                    ...info,
                                    birthDate: value,
                                })
                            } />
                        </div>
                        <div className="field">
                            <input type='email' placeholder='ایمیل' value={info?.email} onChange={(e) => setInfo({ ...info, email: e.target.value })} />
                        </div>
                        <div className='own-number'>
                            <input type='checkbox' checked={isMobileCheckboxChecked} onClick={() => setIsMobileCheckboxChecked(!isMobileCheckboxChecked)} />
                            <p>شماره {parsedProfile.phoneNumber} به نام خودم است.</p>
                        </div>
                        <Button loading={loading} type='primary' text='ثبت و ادامه' icon={<Continue />} onClick={handleSubmit} disabled={loading || info.birthDate === '' || info.nationalCode === '' || isMobileCheckboxChecked === false} />
                    </div>
                }
                {step == 2 &&
                    <div className="step-2-content">
                        <p className='hint'>لطفا بعد از بررسی اطلاعات هویتی خود، صحت آنها را تایید کنید</p>
                        <div className="row">
                            <span>
                                نام و نام خانوادگی
                            </span>
                            <p>
                                {identity?.fullName}
                            </p>
                        </div>
                        <Divider />
                        <div className="row">
                            <span>
                                نام پدر
                            </span>
                            <p>
                                {identity?.fatherName}
                            </p>
                        </div>
                        <Divider />
                        <div className="row">
                            <span>
                                کد ملی
                            </span>
                            <p>
                                {identity?.nationalCode}
                            </p>
                        </div>
                        <Divider />
                        <div className="row">
                            <span>
                                تاریخ تولد
                            </span>
                            <p>
                                {dateSlasher(identity?.birthDate)}
                            </p>
                        </div>
                        <Button className='confirm' type='primary' text='تایید اطلاعات' loading={loading} onClick={handleVerify} />
                        <Button className='reject' type='primary' text='رد اطلاعات' onClick={() => setStep(1)} />
                    </div>

                }
            </Card>
        </>
    )
}