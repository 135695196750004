import { Button, Card } from 'components';
import './faq.scss';
import { useLocation } from 'react-router-dom';
import { Collapse, CollapseProps } from 'antd';
import * as React from 'react';
import { ReactComponent as ChevronDown } from 'assets/icons/orders/ChevronDown.svg';
import { ReactComponent as ChevronDownWhite } from 'assets/icons/main/DownWhite.svg';
import { isMobile } from 'react-device-detect';

const CREATE_ORDER_ITEMS: CollapseProps['items'] = [
  {
    key: '1',
    label: 'مدارک مورد نیاز برای احراز هویت چیست؟',
    children: (
      <p>
        ارسال یک تصویر سلفی مطابق نمونه درج شده بعد از اولین خرید بالای ۵۰۰ هزار
        تومان که در قسمت پیگیری سفارش این اطلاعات از شما دریافت خواهد شد.
      </p>
    ),
  },
  {
    key: '2',
    label:
      'پس از واریز وجه چقدر زمان خواهد برد که ارز به کیف پول ما ارسال شود؟',
    children: (
      <p>
        ارسال یک تصویر سلفی مطابق نمونه درج شده بعد از اولین خرید بالای ۵۰۰ هزار
        تومان که در قسمت پیگیری سفارش این اطلاعات از شما دریافت خواهد شد.
      </p>
    ),
  },
  {
    key: '3',
    label:
      'آیا میتوانیم دو ارز را به یکدیگر تبدیل کنیم ؟ مثلا بیت کوین به اتریوم',
    children: (
      <p>
        ارسال یک تصویر سلفی مطابق نمونه درج شده بعد از اولین خرید بالای ۵۰۰ هزار
        تومان که در قسمت پیگیری سفارش این اطلاعات از شما دریافت خواهد شد.
      </p>
    ),
  },
  {
    key: '4',
    label:
      'آیا میتوانیم یک ارز را بفروشیم و پول را به حساب شخص دیگری واریز نمائیم؟',
    children: (
      <p>
        ارسال یک تصویر سلفی مطابق نمونه درج شده بعد از اولین خرید بالای ۵۰۰ هزار
        تومان که در قسمت پیگیری سفارش این اطلاعات از شما دریافت خواهد شد.
      </p>
    ),
  },
];

const WALLET_ITEMS: CollapseProps['items'] = [
  {
    key: 1,
    label: 'شارژ کیف پول با هر شماره کارتی مجاز است؟',
    children: (
      <p>
        شارژ کیف پول فقط با کارت های ثبت شده و به نام خود کاربر مجاز است و در
        صورت پرداخت با کارت های دیگر تراکنش ناموفق شده و طی 72 ساعت کاری به حساب
        بازگشت داده میشود.
      </p>
    ),
  },
  {
    key: 2,
    label:
      'پس از واریز وجه چقدر زمان خواهد برد که ارز به کیف پول ما ارسال شود؟',
    children: (
      <p>
        شارژ کیف پول فقط با کارت های ثبت شده و به نام خود کاربر مجاز است و در
        صورت پرداخت با کارت های دیگر تراکنش ناموفق شده و طی 72 ساعت کاری به حساب
        بازگشت داده میشود.
      </p>
    ),
  },
  {
    key: 3,
    label:
      'آیا میتوانیم دو ارز را به یکدیگر تبدیل کنیم ؟ مثلا بیت کوین به اتریوم',
    children: (
      <p>
        شارژ کیف پول فقط با کارت های ثبت شده و به نام خود کاربر مجاز است و در
        صورت پرداخت با کارت های دیگر تراکنش ناموفق شده و طی 72 ساعت کاری به حساب
        بازگشت داده میشود.
      </p>
    ),
  },
];

export const FAQ = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Card
      title="سوالات پرتکرار"
      hrefTitle="انتقال به صفحه سوالات پرتکرار"
      href="/dashboard/faq"
      className="xpay-faq"
      divider
    >
      {isMobile ? (
        <>
          <Button
            type={'primary'}
            className={'toggle-button'}
            text={`${!isOpen ? 'مشاهده سوالات پرتکرار' : 'بستن سوالات پرتکرار'}`}
            icon={
              <ChevronDownWhite
                style={{
                  transform: `rotate(${isOpen ? 180 : 0}deg)`,
                  transition: 'all 0.2s ease',
                }}
              />
            }
            onClick={() => setIsOpen(!isOpen)}
          />
          {isOpen && (
            <Collapse
              items={
                location.pathname.startsWith('/dashboard/orders/create-order')
                  ? CREATE_ORDER_ITEMS
                  : WALLET_ITEMS
              }
              className={'xpay-faq-collapse'}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <ChevronDown
                  style={{
                    transform: `rotate(${isActive ? 180 : 0}deg)`,
                    transition: 'all 0.2s ease',
                  }}
                />
              )}
            />
          )}
        </>
      ) : (
        <Collapse
          items={
            location.pathname.startsWith('/dashboard/orders/create-order')
              ? CREATE_ORDER_ITEMS
              : WALLET_ITEMS
          }
          className={'xpay-faq-collapse'}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <ChevronDown
              style={{
                transform: `rotate(${isActive ? 180 : 0}deg)`,
                transition: 'all 0.2s ease',
              }}
            />
          )}
        />
      )}
    </Card>
  );
};
