import * as React from 'react';
import avatar from 'assets/images/avatar.svg';
import 'components/navbar/navbar.scss';
import useOnBlur from 'utils/scripts/useOnBlur';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'components/modal/modal';
import { Button } from 'components/button/button';
import { isLogin } from 'utils/scripts/api';
import { RootState } from 'core/redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { setMenuItem } from 'core/redux/actions';
import { SidebarItems } from 'utils/constants/common';
import moment from 'jalali-moment';
import { useAppDispatch } from '../../core/redux/hooks';
import Notifications from 'components/notification/notification';
import { formatPhoneNumber } from 'utils/scripts/formatPhoneNumber';
import { ProfileType } from 'core/domains/profile/profile';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  sidebarOpen?: boolean;
  profile?: ProfileType;
}
const { useState, useRef } = React;

const Navbar: React.FC<Props> = (props) => {
  // const parsedProfile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile') ?? "") : null
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [notificationsIsOpen, setNotificationsIsOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const parsedProfile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile') ?? '')
      : null;
    setProfileInfo(() => parsedProfile);
  }, []);

  const dropDownRef = useRef<any>(null);

  const location = useLocation();

  useOnBlur(dropDownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const navigate = useNavigate();

  function handleModalSubmit() {
    setModalIsOpen(false);
    navigate('/login');
  }

  function findRoute(link: string) {
    var route = location.pathname.split('/');
    return link ==
      `/${route[1]}/${
        route[3] == 'create-order' ? 'orders/create-order' : route[2]
      }`
      ? true
      : false;
  }

  React.useEffect(() => {
    if (isLogin()) setUserName(localStorage.getItem('phone'));
    else window.open('/login', '_self');
  }, []);
  const dispatch = useAppDispatch();

  const path = location.pathname.trim();

  React.useEffect(() => {
    if (!isLogin()) navigate('/login');
    SidebarItems.map((item) => {
      location.pathname.includes(item?.link) && dispatch(setMenuItem(item.id));
    });
  }, [location.pathname]);

  return (
    <div className={`xpay-navbar ${props.sidebarOpen ? 'smaller' : 'bigger'}`}>
      <Modal
        className="logout-modal"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <div className="confirm-box">
          <div className="head">
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => handleModalSubmit()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>

      <span className="space desktop-show"></span>
      <div className="box">
        <div className="navbar-welcome">
          <h4>
            {props?.profile?.fullName
              ? props?.profile?.fullName + ' '
              : props?.profile?.phoneNumber + ' '}
            عزیز، خوش آمدی.
          </h4>
          <span>
            {moment().locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')}
          </span>
        </div>
        <div className="navbar-user">
          <div
            className="notifications-box"
            onClick={() => setNotificationsIsOpen(!notificationsIsOpen)}
          >
            <Notifications
              isOpen={notificationsIsOpen}
              setIsOpen={setNotificationsIsOpen}
            />{' '}
            <span className="desktop-show">اعلانات</span>
          </div>
          <div
            className="navbar-profile"
            ref={dropDownRef}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="avatar">
              <img src={avatar}></img>
            </div>
            <div className="dropdown">
              <h4>
                {props.state.profileData.level.uniqueName.id
                  ? props.state.profileData.level.uniqueName.id > 1
                    ? props.state.profileData.fullName
                      ? props.state.profileData.fullName
                      : props.state.profileData.phoneNumber
                    : props.state.profileData.phoneNumber
                  : props.state.profileData.phoneNumber}{' '}
              </h4>
              <div className="dropdown-profile">
                <span id="name">{props?.profile?.fullName}</span>
                <span id="phone" className="desktop-show">
                  {formatPhoneNumber(localStorage.getItem('phone'))}
                </span>
              </div>
              <div>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: `rotate(${isOpen ? 180 : 0}deg)`,
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.80113 1.22462L5.48011 5.78989C5.21495 6.07004 4.78505 6.07004 4.51989 5.78989L0.19887 1.22462C-0.0662899 0.944468 -0.0662899 0.490258 0.19887 0.210111C0.464029 -0.0700374 0.893937 -0.0700374 1.1591 0.210111L5 4.26813L8.8409 0.210111C9.10606 -0.070037 9.53597 -0.070037 9.80113 0.210111C10.0663 0.490259 10.0663 0.944468 9.80113 1.22462Z"
                    fill="#2C3E60"
                  />
                </svg>
              </div>
              {isOpen && (
                <div className="dropdown-body">
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.13398 0.499999C6.51888 -0.166667 7.48113 -0.166667 7.86603 0.5L13.0622 9.5C13.4471 10.1667 12.966 11 12.1962 11H1.80385C1.03405 11 0.552922 10.1667 0.937822 9.5L6.13398 0.499999Z"
                      fill="white"
                    />
                  </svg>

                  <Link to="/dashboard/profile" className="item inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.862 4.74733C4.60133 4.48667 4.60133 4.06533 4.862 3.80467C5.12267 3.544 5.544 3.544 5.80467 3.80467L7.33333 5.33333V0.666667C7.33333 0.298 7.63133 0 8 0C8.36867 0 8.66667 0.298 8.66667 0.666667V5.33333L10.1953 3.80467C10.456 3.544 10.8773 3.544 11.138 3.80467C11.3987 4.06533 11.3987 4.48667 11.138 4.74733L8.94267 6.94267C8.68467 7.20067 8.34533 7.33067 8.006 7.332L8 7.33333L7.994 7.332C7.65467 7.33067 7.31533 7.20067 7.05733 6.94267L4.862 4.74733V4.74733ZM14 8H12C11.2647 8 10.6667 8.598 10.6667 9.33333C10.6667 10.0687 10.0687 10.6667 9.33333 10.6667H6.66667C5.93133 10.6667 5.33333 10.0687 5.33333 9.33333C5.33333 8.598 4.73533 8 4 8H2C0.897333 8 0 8.89733 0 10V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V10C16 8.89733 15.1027 8 14 8Z"
                        fill="#859ABC"
                      />
                    </svg>
                    <span>دانلود اپلیکیشن</span>
                  </Link>
                  <Link to="/dashboard/profile" className="item">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.1222 14.0232C21.4953 13.5548 21.222 12.7825 21.222 12C21.222 11.2217 21.5081 10.4557 22.1465 10.0105L23.7398 8.89952C23.974 8.72727 24.0911 8.38278 23.9154 8.09569L21.3391 3.84689C21.1635 3.55981 20.8707 3.44498 20.5779 3.61722L18.8042 4.41731C18.0914 4.73886 17.275 4.63738 16.5959 4.2497C15.9015 3.8533 15.3792 3.1774 15.3054 2.38124L15.1326 0.516746C15.074 0.229665 14.8398 0 14.547 0H9.51152C9.21876 0 8.926 0.229665 8.926 0.516746L8.75041 2.41068C8.67828 3.18871 8.15014 3.85531 7.46711 4.23481C6.7712 4.62147 5.95126 4.73167 5.22555 4.40432L3.48062 3.61722C3.18786 3.50239 2.83654 3.61722 2.71944 3.84689L0.201681 8.09569C0.0260233 8.38278 0.143128 8.72727 0.377338 8.89952L1.97061 10.0105C2.60903 10.4557 2.89509 11.2217 2.89509 12C2.89509 12.7757 2.60121 13.5376 1.95612 13.9682L0.260233 15.1005C0.0260233 15.2727 -0.0910816 15.6172 0.0845758 15.9043L2.60233 20.1531C2.77799 20.4402 3.07075 20.555 3.36351 20.3828L5.13726 19.5827C5.85009 19.2611 6.66642 19.3626 7.34555 19.7503C8.03995 20.1467 8.56223 20.8226 8.63604 21.6188L8.80889 23.4833C8.86744 23.7703 9.10165 24 9.39442 24H14.4299C14.7227 24 15.0155 23.7703 15.0155 23.4833L15.191 21.5893C15.2632 20.8113 15.7913 20.1447 16.4743 19.7652C17.1702 19.3785 17.9902 19.2683 18.7159 19.5957L20.4608 20.3828C20.7536 20.4976 21.1049 20.3828 21.222 20.1531L23.7398 15.9043C23.9154 15.6172 23.7983 15.2727 23.5641 15.1005L22.1222 14.0232ZM11.9707 17.7416C8.75034 17.7416 6.11548 15.1579 6.11548 12C6.11548 8.8421 8.75034 6.25837 11.9707 6.25837C15.1911 6.25837 17.826 8.8421 17.826 12C17.826 15.1579 15.1911 17.7416 11.9707 17.7416Z"
                        fill="#859ABC"
                      />
                    </svg>
                    <span>حساب کاربری</span>
                  </Link>
                  <Link to="/dashboard/authentication" className="item">
                    <svg
                      width="30"
                      height="24"
                      viewBox="0 0 30 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9853 12.055C29.5453 12.055 30 12.5008 30 13.0513C30 13.6004 29.5453 14.0449 28.9853 14.0449H27.5693V18.791C27.5693 21.6649 25.1866 24 22.2586 24H20.1346C19.5733 24 19.1186 23.5542 19.1186 23.0037C19.1186 22.4546 19.5733 22.0087 20.1346 22.0087H22.2586C24.068 22.0087 25.54 20.5666 25.54 18.791V14.0449H22.0426V15.3668C22.056 17.0207 20.7026 18.3727 19.016 18.3884H11.6506C9.96529 18.3727 8.61196 17.0207 8.62529 15.3668V14.0449H5.12796V18.7963C5.12796 20.5653 6.59596 22.0048 8.39996 22.0074L10.5706 22.0087C11.1306 22.0087 11.5853 22.4546 11.5853 23.0037C11.584 23.5542 11.1306 24 10.5693 24L8.39729 23.9987C5.47596 23.9961 3.09729 21.6622 3.09729 18.7963V14.0449H1.68263C1.12263 14.0449 0.666626 13.6004 0.666626 13.0513C0.666626 12.5008 1.12263 12.055 1.68263 12.055H28.9853ZM19.0166 4.99021C19.8233 4.99021 20.598 5.30793 21.162 5.87406C21.73 6.44542 22.0446 7.2129 22.0433 8.01177V9.67225C22.0366 9.86706 21.878 10.0253 21.6793 10.0305H8.98596C8.78863 10.024 8.62996 9.86576 8.62584 9.67225V8.01177C8.61129 6.35782 9.96596 5.00721 11.6513 4.99021H19.0166ZM22.2581 0.00039224C25.1874 0.00039224 27.5688 2.33553 27.5688 5.20803V7.68307C27.5688 8.23221 27.1154 8.67936 26.5541 8.67936C25.9941 8.67936 25.5394 8.23221 25.5394 7.68307V5.20803C25.5394 3.4338 24.0674 1.99036 22.2581 1.99036H20.1341C19.5741 1.99036 19.1194 1.54582 19.1194 0.996682C19.1194 0.446239 19.5741 0.00039224 20.1341 0.00039224H22.2581ZM10.5702 0C11.1302 0 11.5849 0.445846 11.5849 0.99629C11.5849 1.54543 11.1316 1.99127 10.5702 1.99127L8.39823 1.99258C6.59556 1.9952 5.12889 3.43472 5.12889 5.20372V7.68268C5.12889 8.23181 4.67289 8.67897 4.11289 8.67897C3.55289 8.67897 3.09823 8.23181 3.09823 7.68268V5.20372C3.09823 2.33775 5.47556 0.0039224 8.39689 0.00130747L10.5702 0Z"
                        fill="#859ABC"
                      />
                    </svg>
                    <span>احراز هویت</span>
                  </Link>
                  <span className="divider"></span>
                  <div className="item" onClick={() => setModalIsOpen(true)}>
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.94858 12.9239H1.13776C0.626226 12.9239 0.199951 12.5159 0.199951 11.9999C0.199951 11.4959 0.626226 11.0759 1.13776 11.0759H5.94858L5.94894 5.34C5.94894 2.4 8.42729 0 11.4633 0H17.5104C20.534 0 23 2.4 23 5.328V18.672C23 21.612 20.534 24 17.4856 24H11.4509C8.42729 24 5.94894 21.612 5.94894 18.684V12.924H13.6318L11.6491 14.844C11.2774 15.204 11.2774 15.792 11.6491 16.152C11.835 16.332 12.0828 16.416 12.3307 16.416C12.5661 16.416 12.814 16.332 12.9998 16.152L16.6182 12.66C16.8041 12.492 16.9032 12.252 16.9032 12C16.9032 11.76 16.8041 11.52 16.6182 11.352L12.9998 7.86C12.6281 7.5 12.0209 7.5 11.6491 7.86C11.2774 8.22 11.2774 8.808 11.6491 9.168L13.6318 11.076H5.94894L5.94858 12.9239Z"
                        fill="#859ABC"
                      />
                    </svg>
                    <span>خروج</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(Navbar);
