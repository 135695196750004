import { Slider } from 'components/slider/slider';
import * as React from 'react';
import './main.scss';
import { Asset } from 'core/domains/markets/asset';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { Divider, Tooltip } from 'antd';
import { ActionBox } from './action-box/action-box';
import { ReferralBox } from './referral-box/referral-box';
import { WalletBox } from './wallet-box/wallet-box';
import { AuthenticationBox } from './authentication-box/authentication-box';
import { Alert } from 'components';
import { PopularCurrenciesBox } from './popular-currencies-box/popular-currencies-box';
import { isMobile } from 'react-device-detect';
import { UpdateApplicationModal } from 'components/update-application-modal/update-application-modal';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const Main: React.FC<Props> = (props) => {
  return (
    <>
      <Alert
        closeAble
        className="desktop-show"
        type="warning"
        title="اخطار!"
        message="کاربر گرامی؛ آگهـی هـای کاریـابی کـه بـه درخـواسـت از شمــا جهــت ثبـت نـام در سایـت هـای فروش ارز دیجیتال و اقدام جهت خرید رمز ارز ختم می گردد،کلاهبرداری و به قصد سو استفاده از هویـت و حساب بانکی شما با اهداف مجرمانه می باشند و مسئولیت قانونی آن بر عهده شماست."
      />
      {isMobile && (
        <Alert
          closeAble
          type="warning"
          title="اخطار!"
          message="کاربر گرامی؛ آگهـی هـای کاریـابی کـه بـه درخـواسـت از شمــا جهــت ثبـت نـام در سایـت هـای فروش ارز دیجیتال و اقدام جهت خرید رمز ارز ختم می گردد،کلاهبرداری و به قصد سو استفاده از هویـت و حساب بانکی شما با اهداف مجرمانه می باشند و مسئولیت قانونی آن بر عهده شماست."
        />
      )}
      <div className="xpay-dashboard-main">
        <Slider />
        <ActionBox />
        <ReferralBox />
        <Divider
          className="desktop-show"
          type="vertical"
          style={{
            gridArea: 'divider',
            height: 'calc(100% + 48px)',
            transform: 'translateY(-24px)',
          }}
        />
        <WalletBox />
        <AuthenticationBox />
        <PopularCurrenciesBox />
      </div>
    </>
  );
};

export default connector(Main);
