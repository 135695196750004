import { Divider } from 'antd';
import { Fiat } from 'core/domains';
import { Asset } from 'core/domains/markets/asset';
import { Market } from 'core/domains/markets/market';
import {
  getAllCommissions,
  getAllFiats,
  getAllOrderMarketNameIds,
} from 'core/repositories';
import * as React from 'react';
import { SetStateAction, useEffect } from 'react';
import './create-order.scss';
import { FAQ } from '../faq/faq';
import { TradeBox } from '../trade-box/trade-box';
import { SearchBox } from '../search-box/search-box';
import { getMineAssets } from '../../../../core/repositories/wallet/wallet';

export enum Side {
  Buy,
  Sell,
}

interface Commission {
  orderSide: number;
  commission: number;
}

export interface OrderCommonProps {
  side: Side;
  markets: Market[];
  fiats: Fiat[];
  assets: Asset;
  commissions: Commission[];
  sourceMarket: Market;
  destinationMarket: Market;
  marketsLoading: boolean;
  fiatsLoading: boolean;
  assetsLoading: boolean;
  commissionsLoading: boolean;
  setSide: React.Dispatch<SetStateAction<Side>>;
  setMarketsLoading: React.Dispatch<SetStateAction<boolean>>;
  setFiatsLoading: React.Dispatch<SetStateAction<boolean>>;
  setAssetsLoading: React.Dispatch<SetStateAction<boolean>>;
  setCommissionsLoading: React.Dispatch<SetStateAction<boolean>>;
  setSourceMarket: React.Dispatch<SetStateAction<Market>>;
  setDestinationMarket: React.Dispatch<SetStateAction<Market>>;
}

const CreateOrder: React.FC = () => {
  const [markets, setMarkets] = React.useState<Market[]>([]);
  const [fiats, setFiats] = React.useState<Fiat[]>([]);
  const [assets, setAssets] = React.useState<Asset>();
  const [commissions, setCommissions] = React.useState<Commission[]>([]);

  const [marketsLoading, setMarketsLoading] = React.useState(false);
  const [fiatsLoading, setFiatsLoading] = React.useState(false);
  const [assetsLoading, setAssetsLoading] = React.useState(false);
  const [commissionsLoading, setCommissionsLoading] = React.useState(false);

  const queryParams = new URLSearchParams(location.search);
  const urlSide = queryParams.get('side') || 'BUY';
  const urlSymbol = queryParams.get('symbol') || 'BTC';

  const initialMarkets = {
    source: {
      id: '32cb59f0-941c-ed11-b851-9d6f4adb3d5c',
      name: 'تومان',
      symbol: 'IRT',
      isSellActive: true,
      isBuyActive: true,
      type: {
        id: 2,
        title: 'پرفکت مانی',
      },
      iconUrl: '/coins/IRT.png',
    },
    destination: {
      id: '34cb59f0-941c-ed11-b851-9d6f4adb3d5c',
      name: 'بیت کوین',
      symbol: urlSymbol ? urlSymbol?.toUpperCase() : 'BTC',
      isSellActive: true,
      isBuyActive: true,
      type: {
        id: 1,
        title: 'کریپتو',
      },
      iconUrl: '/coins/BTC.png',
    },
  };

  const [sourceMarket, setSourceMarket] = React.useState<Market>(
    initialMarkets.source,
  );
  const [destinationMarket, setDestinationMarket] = React.useState<Market>(
    initialMarkets.destination,
  );

  const [side, setSide] = React.useState<Side>(Side.Buy);

  const handleURLQueries = () => {
    if (location.search !== '') {
      setSide(urlSide.toUpperCase() === 'BUY' ? Side.Buy : Side.Sell);

      if (urlSide.toUpperCase() === 'BUY') {
        setDestinationMarket(
          markets.find(
            (market) =>
              market?.symbol?.toUpperCase() === urlSymbol.toUpperCase(),
          ) ?? initialMarkets.destination,
        );
        setSourceMarket(initialMarkets.source);
      } else if (urlSide.toUpperCase() === 'SELL') {
        setSourceMarket(
          markets.find(
            (market) =>
              market?.symbol?.toUpperCase() === urlSymbol.toUpperCase(),
          ) ?? initialMarkets.destination,
        );
        setDestinationMarket(initialMarkets.source);
      }
    }
    getAllFiats(setFiatsLoading, setFiats);
    getAllCommissions(setCommissionsLoading, setCommissions);
    getMineAssets(setAssetsLoading, setAssets, { amountSort: 2 }, '');
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllOrderMarketNameIds(setMarketsLoading, setMarkets, 1, 1000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (markets.length > 0) handleURLQueries();
  }, [markets]);

  return (
    <div className="xpay-create-order">
      <section>
        <SearchBox
          side={side}
          setSide={setSide}
          markets={markets}
          fiats={fiats}
          assets={assets!}
          commissions={commissions}
          sourceMarket={sourceMarket!}
          destinationMarket={destinationMarket!}
          marketsLoading={marketsLoading}
          fiatsLoading={fiatsLoading}
          assetsLoading={assetsLoading}
          commissionsLoading={commissionsLoading}
          setMarketsLoading={setMarketsLoading}
          setFiatsLoading={setFiatsLoading}
          setAssetsLoading={setAssetsLoading}
          setCommissionsLoading={setCommissionsLoading}
          setSourceMarket={setSourceMarket}
          setDestinationMarket={setDestinationMarket}
        />
        <TradeBox
          side={side}
          setSide={setSide}
          markets={markets}
          fiats={fiats}
          assets={assets!}
          commissions={commissions!}
          sourceMarket={sourceMarket!}
          destinationMarket={destinationMarket!}
          marketsLoading={marketsLoading}
          fiatsLoading={fiatsLoading}
          assetsLoading={assetsLoading}
          commissionsLoading={commissionsLoading}
          setMarketsLoading={setMarketsLoading}
          setFiatsLoading={setFiatsLoading}
          setAssetsLoading={setAssetsLoading}
          setCommissionsLoading={setCommissionsLoading}
          setSourceMarket={setSourceMarket}
          setDestinationMarket={setDestinationMarket}
        />
      </section>
      <Divider />
      <FAQ />
    </div>
  );
};

export default CreateOrder;
