import { API } from 'apis';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { toast } from 'react-toastify';
import { ProfileType } from '../../domains/profile/profile';
import { KEY_PROFILE } from '../../../apis/local-storage-constants';

export const getMineProfile = (setLoader: any, setResponse: any) => {
  setLoader(true);
  var profile = localStorage.getItem('profile');

  if (profile) {
    setResponse(JSON.parse(profile));
  } else {
    getViaAuth(API.profile.getProfile).then((response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        localStorage.setItem('name', response.value.fullName);
        localStorage.setItem('profile', JSON.stringify(response.value));
        setResponse(response.value);
      }
    });
  }
};

export const getMineProfileOtp = (
  setLoader: any,
  setResponse: any,
  setOtpType: any,
) => {
  setLoader(true);
  getViaAuth(API.profile.getProfile).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
      setOtpType(
        response.value.twoFactor.isActive
          ? response.value.twoFactor.type.id === 1
            ? 'sms'
            : 'google'
          : 'none',
      );
    }
  });
};

export const getQrCodeOtp = (
  setLoader: any,
  setData: any,
  onComplete?: any,
) => {
  setLoader(true);

  getViaAuth(API.profile.getQrCode).then(function (response: any) {
    setLoader(false);

    if (responseValidator(response.status)) {
      setData(response.value);
      onComplete && onComplete();
    } else {
      if (response.status === 400)
        if (response.status === 401) window.open('/login', '_self');
    }
  });
};

export const setupOtp = (
  setLoader: any,
  setError: any,
  error: any,
  data: any,
  setStage: any,
  setIsOpenModal: any,
  type?: 'sms' | 'google',
) => {
  setLoader(true);
  setError(undefined);
  if (data?.phoneCode && data.phoneCode.length === 6) {
    postViaAuth(
      API.profile.setupOtp,
      type === 'google'
        ? { code: data.phoneCode, type: 2 }
        : { code: data.phoneCode, type: 1 },
    ).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        setIsOpenModal(false);
      } else {
        if (response.status === 400)
          setError({
            ...error,
            phoneCode: response.message,
          });
        if (response.status === 401 || response.status === 500)
          window.open('/login', '_self');
      }
    });
  } else {
    setLoader(false);
    setError({
      ...error,
      phoneCode: 'کد تایید باید دارای ۶ رقم باشد',
    });
  }
};

export const disableOtp = (setLoader: any, code: string) => {
  setLoader(true);
  if (code !== '' && code.length === 6) {
    postViaAuth(API.profile.disableOtp, {
      code: code,
    }).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        toast.success('تایید دو مرحله ای غیرفعال شد.');
        window.location.reload();
      }
    });
  } else toast.error('کد تایید باید دارای ۶ رقم باشد');
};

export const setupGoogleAuth = (
  setLoader: any,
  code: string,
  onComplete: () => void,
) => {
  setLoader(true);
  console.log(code);
  if (code.length === 6) {
    postViaAuth(API.profile.setupOtp, {
      code: code,
      type: 2,
    }).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        onComplete();
      }
    });
  }
};

export function readProfile() {
  var profileData = localStorage.getItem(KEY_PROFILE);
  if (profileData) {
    var profileDataObj = JSON.parse(profileData);
    return profileDataObj as ProfileType;
  }
}
