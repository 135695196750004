import React from 'react';
import './authentication-stepper.scss';

interface AuthenticationStepperProps {
  step: number;
}

const stepTexts = [
  'تلفن ثابت',
  'آدرس محل سکونت',
  'عکس مدارک',
  'عکس سلفی',
];

export const AuthenticationStepper: React.FC<AuthenticationStepperProps> = ({ step }) => {
  return (
    <div className="xpay-authentication-stepper">
      {[1, 2, 3, 4].map((s) => (
        <React.Fragment key={s}>
          {s > 1 && <div className={`line ${s <= step ? 'active' : ''}`} />}
          <div className={`step ${s <= step ? 'active' : ''}`}>
            <div className="circle">{s}</div>
            <div className="text">{stepTexts[s - 1]}</div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
