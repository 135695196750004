import * as React from 'react';
import './sidebar-item.scss';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { setMenuItem, setSidebar } from 'core/redux/actions';
import { useAppDispatch } from 'core/redux/hooks';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction, useState } from 'react';
import { SidebarItemChild } from '../sidebar-item-child/sidebar-item-child';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';

const mapState = (state: RootState) => ({
  state: state,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SidebarProps extends PropsFromRedux {
  id?: number;
  title?: string;
  icon?: React.ReactNode;
  link?: string;
  className?: string;
  filled?: boolean;
  setStage?: React.Dispatch<React.SetStateAction<'PARENT' | 'CHILDREN'>>;
  childrens?: {
    id: number;
    title: string;
    link: string;
  }[];
  setMenuChildItems?: React.Dispatch<
    React.SetStateAction<
      { id: number; title: string; link: string }[] | undefined
    >
  >;
  onClickHandle?: () => void;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const SidebarItem: React.FC<SidebarProps> = ({
  className,
  id,
  state,
  title,
  icon,
  link,
  childrens,
  filled,
  setStage,
  setMenuChildItems,
  onClickHandle,
  sidebarOpen,
  setSidebarOpen,
}) => {
  const dispatch = useAppDispatch();
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (childrens && childrens.length > 0) {
      e.preventDefault();
      console.log(sidebarOpen);
      !sidebarOpen && setSidebarOpen(true);
      setShowChildren(!showChildren);
    } else if (onClickHandle) {
      onClickHandle();
    }
  };

  return (
    <>
      <Link
        to={link ?? ''}
        onClick={(e) => handleItemClick(e)}
        className={`sidebar-item ${sidebarOpen ? 'open' : 'closed'} ${location.pathname === link || childrens?.some((child) => location.pathname.startsWith(child.link)) ? 'active' : ''} ${className ?? ''}`}
      >
        <div className="content">
          {icon}
          {sidebarOpen && <p className="title">{title}</p>}
        </div>
        {childrens && (
          <LeftArrowIcon
            className={`left-arrow-icon ${location.pathname === link || childrens?.some((child) => location.pathname.startsWith(child.link)) ? 'active' : ''}`}
            style={showChildren ? { transform: 'rotate(-90deg)' } : undefined}
          />
        )}
      </Link>
      {showChildren && childrens && sidebarOpen && (
        <div className={'sidebar-children'}>
          {childrens.map((item) => (
            <SidebarItemChild
              key={item.id}
              sidebarOpen={sidebarOpen}
              link={item.link}
              title={item.title}
              id={item.id}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default connector(SidebarItem);
