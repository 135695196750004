import './success.scss';
import { Alert, Button, Card } from '../../../../components';
import union from 'assets/images/create-order/union.png';
import { ReactComponent as SuccessIcon } from 'assets/icons/create-order/success.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/wallet/Copy.svg';
import { Divider } from 'antd';
import { TransactionVerify } from '../../../../core/domains/transactions/transactionVerify';
import { FC, useRef } from 'react';
import moment from 'jalali-moment';
import { CopyOutlined } from '@ant-design/icons';
import { copyToClipboard } from '../../../../utils/scripts/copyToClipboard';
import * as React from 'react';

interface Props {
  verifyData: TransactionVerify | null;
  trackingCode: string;
}

export const Success: FC<Props> = ({ verifyData, trackingCode }) => {
  const orderIdRef = useRef<HTMLElement>(null);

  return (
    <div className={'xpay-success'}>
      <Alert
        type={'success'}
        title={'تراکنش موفق!'}
        message={
          'پرداخـت شمـا با موفقیـت انجـام شـد، از طریق کادر زیر میتوانیـد اطلاعات مورد نیاز خود را کپی کرده و استفـاده نماییـد، علاوه بر این در هر ساعـت از شبانـه روز با مراجعه به صفحـه سوابق میتوانید به اطلاعات پرداخت خود دسترسی داشته باشید.'
        }
        closeAble
      />
      <Card className={'success-card'}>
        <img src={union} className={'union'} />
        <div className={'header'}>
          <div className={'content'}>
            <h1>پرداخت شما با موفقیت انجام شد!</h1>
            <p>
              از طریق صفحه سوابق میتوانید به تاریخچه ی خرید های خود دسترسی داشته
              باشید و از وضعیت سفارشات خود مطلع شوید.
            </p>
          </div>
          <div className={'badge-container'}>
            <SuccessIcon />
          </div>
        </div>
        <Divider />
        <div className={'info-container'}>
          <ul>
            <li>
              <span>شناسه سفارش</span>
              <Divider />
              <span>
                <span className="en" ref={orderIdRef}>
                  {trackingCode}
                </span>
                <CopyOutlined
                  onClick={() =>
                    copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                  }
                />
              </span>
            </li>
            <li>
              <span>زمان پرداخت</span>
              <Divider dashed />
              <span>
                {moment()
                  .locale('fa')
                  .format('dddd jD jMMMM jYYYY، ساعت HH:mm')}
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>وضعیت</span>
              <Divider dashed />
              <span>موفق</span>
            </li>
          </ul>
        </div>
        <Divider />
        <div className={'buttons'}>
          <Button
            type={'primary'}
            text={'مشاهده سوابق'}
            url={'/dashboard/orders'}
          />
          <Button
            type={'outlined'}
            text={'بازگشت به صفحه اصلی'}
            url={'/dashboard'}
          />
        </div>
      </Card>
    </div>
  );
};
