import { API } from 'apis';
import { Profile } from 'core/domains';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
  uploadMultipleViaAuth,
  uploadViaAuth,
} from 'utils/scripts/api';
import { toast } from 'react-toastify';

export const getMineLevelAsync = async (setloader: any, setdata: any) => {
  setloader(true);
  await getViaAuth<Profile>(API.level.mine).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
      localStorage.setItem('level', JSON.stringify(response.value));
    }
  });
};

export const checkForLandLine = async (setStep: any) => {
  await getViaAuth(API.level.hasLandline).then((response: any) => {
    if (responseValidator(response.status)) {
      if (response.value.userHasLandLine === true) setStep(3);
    }
  });
};

export const checkForAddressAndPostalCode = async (setStep: any) => {
  await getViaAuth(API.level.hasAddressAndPostalCode).then((response: any) => {
    if (responseValidator(response.status)) {
      if (response.value.userHasAddressOrPostalCode === true) setStep(4);
    }
  });
};

export const checkForLevel = async (setStep: any, setRejectionMessage: any) => {
  await getViaAuth<Profile>(API.level.mine).then((response: any) => {
    if (responseValidator(response.status)) {
      if (response.value.upgradeRequestState === 0) setStep(6); //تایید شده
      if (response.value.upgradeRequestState === 1) {
        //در انتظار تایید
        setStep(6);
      }
      if (response.value.upgradeRequestState === 2) {
        //رد شده
        setStep(7);
        setRejectionMessage(response.value.rejectUpgradeMessage);
      }
    }
  });
};

export const requestUpgradeToLevel1 = async (
  setLoading: any,
  data: { nationalCode: string; birthDate: string; email: string },
  setData: any,
  onComplete: any,
) => {
  setLoading(true);
  await postViaAuth(API.level.requestUpgradeToLevelOne, data).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData(response.value);
        onComplete();
      }
    },
  );
};

export const verifyUpgradeToLevel1 = async (
  setLoading: any,
  onComplete: any,
) => {
  setLoading(true);
  await postViaAuth(API.level.verifyUpgradeToLevelOne, {
    isConfirmed: true,
  }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success('شما با موفقیت به سطح 1 ارتقا یافتید.');
      onComplete();
    }
  });
};

export const landLineUpgradeToLevel2 = async (
  setLoading: any,
  data: { landLine: string },
  onComplete?: any,
) => {
  setLoading(true);
  await postViaAuth(API.level.callLandLineUpgradeToLevelTwo, data).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success('درخواست تماس ثبت شد');
        onComplete();
      }
    },
  );
};

export const verifyLandLineUpgradeToLevel2 = async (
  setLoading: any,
  data: { landLine: string; otpCode: string },
  onComplete?: any,
) => {
  setLoading(true);
  await postViaAuth(API.level.verifyLandLineUpgradeToLevelTwo, data).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success('کد با موفقیت ثبت شد');
        onComplete();
      }
    },
  );
};

export const addAddressAndPostalCodeUpgradeToLevel2 = async (
  setLoading: any,
  data: { address: string; postalCode: string },
  onComplete?: any,
) => {
  setLoading(true);
  await postViaAuth(
    API.level.addAddressAndPostalCodeUpgradeToLevelTwo,
    data,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success('آدرس و کد پستی با موفقیت ثبت شد');
      onComplete();
    }
  });
};

export const level4Post = (
  setLoader: any,
  data: any,
  setStage: any,
  setIsOpen: any,
) => {
  setLoader(true);
  postViaAuth(API.authentication.upgradeToLevel4, {
    landline: `${data.cityCode}${data.landline}`,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      if (response.value.isSentCode && response.value.isSentCode == true)
        setIsOpen(true);
      else window.location.reload();
      toast.success('درخواست شما ارسال شد.');
    }
  });
};

export const level4VerifyPost = (
  setLoader: any,
  data: any,
  setIsOpen: any,
  setCodeError: any,
) => {
  setLoader(true);

  postViaAuth(API.authentication.verifyUpgradeToLevel4, {
    landline: `${data.cityCode}${data.landline}`,
    otpCode: data.code,
  }).then(function (response: any) {
    setLoader(false);
    if (responseValidator(response.status)) {
      setIsOpen(false);
      toast.success('شماره تماس تایید شد');
      window.location.reload();
    } else {
      if (response.status === 400) setCodeError(response.message);
    }
  });
};

export const upgradeToLevel2 = async (
  file: File,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
) => {
  const formData = new FormData();
  formData.append('DocumentFile', file);

  try {
    await uploadViaAuth(
      API.authentication.upgradeToLevel2,
      formData,
      setPercent,
      setUploadCancelSource,
    );

    setLoading(false);
    toast.success('تصویر آپلود شد');
  } catch (error) {
    setLoading(false);
    if (error instanceof DOMException && error.name === 'AbortError') {
      toast.error('آپلود تصویر لغو شد');
    } else {
      toast.error('آپلود تصویر لغو شد');
    }
  }
};

export const upgradeToLevel3 = async (
  formData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPercent: React.Dispatch<React.SetStateAction<number>>,
  setUploadCancelSource: React.Dispatch<
    React.SetStateAction<AbortController | null>
  >,
) => {
  try {
    setLoading(true);
    await uploadMultipleViaAuth(
      API.authentication.upgradeToLevel3,
      formData,
      setPercent,
      setUploadCancelSource,
    );
    toast.success('تصاویر آپلود شدند');
    setLoading(false);
  } catch (error) {
    setLoading(false);
    if (error instanceof DOMException && error.name === 'AbortError') {
      toast.error('آپلود تصاویر لغو شد');
    } else {
      toast.error('آپلود تصاویر لغو شد');
    }
  }
};
