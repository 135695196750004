import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { useLocation } from 'react-router-dom';
import './bottom-menu-item.scss';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  id: number;
  title: string;
  link?: string;
  icon: ReactNode;
  secondaryLink?: string;
}

const BottomMenuItem: React.FC<Props> = (props) => {
  const location = useLocation();
  const path = location.pathname.trim();

  return (
    <Link
      className={`xpay-bottom-menu-item ${path === props.link || path === props.secondaryLink ? 'active' : ''}`}
      key={props.id}
      to={props.link ?? ''}
      state={{ from: path }}
    >
      {props.icon}
      <span>{props.title}</span>
    </Link>
  );
};

export default connector(BottomMenuItem);
