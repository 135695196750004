import animationData from 'core/lotties/XPay.json';
import Lottie, { Options } from 'react-lottie';
import { Spin } from 'antd';
import { SpinType } from 'antd/es/spin';
import './spinner.scss';

export const Spinner = ({ children, loading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <Lottie
          options={defaultOptions}
          width={56}
          height={56}
          style={{
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            position: 'absolute',
          }}
        />
      }
      className={'xpay-spinner'}
    >
      {children}
    </Spin>
  );
};
