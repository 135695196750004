import * as React from 'react';
import './authentication.scss';
import { UserLevel } from 'core/domains';
import { Divider } from 'antd';
import { Card } from 'components';
import { getMineLevelAsync } from 'core/repositories';
import diamond from '../../../assets/icons/authentication/diamond.png';
import { AuthenticationCard } from './authentication-card/authentication-card';
import moment from 'jalali-moment';
import { FC, useEffect, useState } from 'react';
import { FishingModal } from './fishing-modal/fishing-modal';
import { readProfile } from '../../../core/repositories/profile/profile';
import { KEY_FISHING_MODAL_SEEN } from '../../../apis/local-storage-constants';

export const Authentication: FC = () => {
  const profile = JSON.parse(localStorage.getItem('profile') ?? 'null');
  const [level, setLevel] = useState<UserLevel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getMineLevelAsync(setLoading, setLevel);

    if (
      (readProfile()?.level.uniqueName.id === 1 ||
        readProfile()?.level.uniqueName.id === 3) &&
      localStorage.getItem(KEY_FISHING_MODAL_SEEN) !== 'true'
    ) {
      setModalIsOpen(true);
      localStorage.setItem(KEY_FISHING_MODAL_SEEN, 'true');
    }
    console.log(
      'CONDITION:',
      readProfile()?.level.uniqueName.id === 1 ||
        readProfile()?.level.uniqueName.id === 2,
    );
  }, []);

  return (
    <div className="xpay-dashboard-authentication">
      <FishingModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
      <Card
        className="desktop-show"
        title="وضعیت حساب کاربری"
        href="/dashboard"
        hrefTitle="انتقال  به صفحه حساب کاربری"
        divider
        blur={loading}
      >
        <div className="row">
          <div className="box">
            <div className="diamond">
              <img src={diamond} width={32} height={32} />
            </div>
            <div className="col">
              <div className="row">
                <span>سطح کاربری فعلی: </span> {level?.name}
              </div>
              <div className="row">
                <span>شماره موبایل: </span> {profile?.phoneNumber}
              </div>
            </div>
          </div>
          <Divider type="vertical" style={{ height: 'unset', width: '4px' }} />
          <div className="col">
            <div className="row">
              <span>تاریخ ثبت نام: </span>
              {moment(level?.createdOn)
                .locale('fa')
                .local()
                .format('jD jMMMM jYYYY')}
            </div>
            <div className="row">
              <span>آی پی شما: </span>
              {level?.ip}
            </div>
          </div>
        </div>
      </Card>

      <Card className="mobile-show" title="وضعیت حساب کاربری" divider>
        <div className="mobile-content">
          <div className="diamond">
            <img src={diamond} width={32} height={32} />
          </div>
          <div className="col">
            <div className="row">
              <span>سطح کاربری فعلی: </span> {profile?.level?.title}
            </div>
            <div className="row">
              <span>شماره موبایل: </span> {profile?.phoneNumber}
            </div>
            <div className="row">
              <span>تاریخ ثبت نام: </span> 24 مهر 1402
            </div>
            <div className="row">
              <span>آی پی شما: </span> 127.0.0.1
            </div>
          </div>
        </div>
      </Card>

      <Card
        className="authentication"
        divider
        title="احراز هویت"
        hrefTitle="بازگشت به صفحه اصلی"
        href="/dashboard"
      >
        <div className="row">
          <AuthenticationCard stage={0} />
          <AuthenticationCard stage={1} />
          <AuthenticationCard stage={2} />
        </div>
      </Card>
    </div>
  );
};
